import { AxiosResponse } from 'axios'
import invariant from 'tiny-invariant'
import {
  GenerationStrategyAPI,
  serviceGenerationStrategy,
} from '~/services/base'
import {
  ResponseGetGenerationStrategy,
  ResponseListGenerationStrategies,
  ResponseListParametersByGenerationId,
} from '~/services/GenerationStrategy.types'

export const queryKeyStrategiesByOrganisationId = (organisationId?: GUID) => [
  'generation-strategy',
  'by-organisation-id',
  { organisationId },
]
export const queryStrategiesByOrganisationId = <
  T = ResponseListGenerationStrategies[],
>(
  organisationId?: GUID,
) => {
  invariant(organisationId, 'Organisation ID is required')

  return {
    queryKey: queryKeyStrategiesByOrganisationId(organisationId),
    queryFn: async () => {
      const url =
        GenerationStrategyAPI.listStrategiesByOrganisationId(organisationId)
      const req = await serviceGenerationStrategy(url)
      return req as AxiosResponse<T, unknown>
    },
  }
}

export const queryKeyStrategiesForPublish = (organisationId?: GUID) => [
  'generation-strategy',
  'by-organisation-id',
  { organisationId },
]
export const queryStrategiesForPublish = <
  T = ResponseListGenerationStrategies[],
>(
  organisationId?: GUID,
) => {
  invariant(organisationId, 'Organisation ID is required')

  return {
    queryKey: queryKeyStrategiesForPublish(organisationId),
    queryFn: async () => {
      const url = GenerationStrategyAPI.listStrategiesForPublish(organisationId)
      const req = await serviceGenerationStrategy(url)
      return req as AxiosResponse<T, unknown>
    },
  }
}

export const mutationConvertFileToTemplate = async (fileId: string) => {
  const url = GenerationStrategyAPI.ConvertFileToTemplate

  const postData = {
    fileId,
  }

  return serviceGenerationStrategy.post(url, postData)
}

export const queryKeyStrategy = (id: GUID) => ['generation-strategy', { id }]

export const queryStrategyById = <T = ResponseGetGenerationStrategy>(
  id?: GUID,
) => {
  invariant(id, 'Generation Strategy ID is required')

  return {
    queryKey: queryKeyStrategy(id),
    queryFn: async () => {
      const url = GenerationStrategyAPI.getStrategyById(id)
      const req = await serviceGenerationStrategy(url)
      return req as AxiosResponse<T, unknown>
    },
  }
}

export const queryKeyBindingParameters = (id: GUID) => [
  'generation-strategy',
  'binding-options',
  { id },
]
export const queryListBindingParameters = <
  T = ResponseListParametersByGenerationId[],
>(
  id?: GUID,
) => {
  invariant(id, 'Generation Strategy ID is required')

  return {
    queryKey: queryKeyBindingParameters(id),
    queryFn: async () => {
      const url = GenerationStrategyAPI.ListBindingParameters(id)
      const req = await serviceGenerationStrategy(url)
      return req as AxiosResponse<T, unknown>
    },
  }
}
