import { ButtonGroup, IconButton, Tooltip } from '@mui/material'
import { AxiosResponse } from 'axios'
import React from 'react'
import { useLoaderData, useNavigate } from 'react-router-dom'
import EditIcon from '~/assets/icons/edit.svg?react'
import PublishIcon from '~/assets/icons/rocket.svg?react'
import DeleteIcon from '~/assets/icons/trash.svg?react'
import { useGetSubscriptionPlanCapabilities } from '~/hooks/useGetSubscriptionPlanCapabilities'
import { DomainByPlatformId } from '~/services/Development.types'
import { PlatformByOrganisation } from '~/services/PlatformTeam.types'
import { STATEMENT_PARSING_INFO_TYPE } from '~/services/Process.types'
import {
  commandsByAggregate,
  reactionsByAggregate,
  readModelByAggregate,
} from '~/utils/transform'

type MenuMoreProps = {
  linkPublish?: string
  linkEdit?: string
  linkDelete?: string
  type?: string
  eventId?: GUID
}

export function MenuMore({
  linkPublish,
  linkEdit,
  linkDelete,
  type,
  eventId,
}: MenuMoreProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const navigate = useNavigate()
  const { codeGeneration: isCodeGenerationAllowed } =
    useGetSubscriptionPlanCapabilities()

  const loaderData = useLoaderData() as {
    platform: AxiosResponse<PlatformByOrganisation>
    development: AxiosResponse<DomainByPlatformId>
  }
  // const dataPlatform = loaderData?.platform?.data
  const responseDevelopment = loaderData?.development?.data
  // const params = useParams()

  const commands = React.useMemo(() => {
    if (responseDevelopment) {
      return commandsByAggregate(responseDevelopment)
    }

    return
  }, [responseDevelopment])
  const reactions = React.useMemo(() => {
    if (responseDevelopment) {
      return reactionsByAggregate(responseDevelopment)
    }

    return
  }, [responseDevelopment])
  const queries = React.useMemo(() => {
    if (responseDevelopment) {
      return readModelByAggregate(responseDevelopment)
    }

    return
  }, [responseDevelopment])

  const selectedCommand = React.useMemo(() => {
    if (
      commands &&
      (type === STATEMENT_PARSING_INFO_TYPE.Command || type === 'command')
    ) {
      return commands.find((command) => command.identity === eventId)
    }

    return
  }, [commands, eventId, type])

  const selectedReaction = React.useMemo(() => {
    if (reactions && type === STATEMENT_PARSING_INFO_TYPE.Reaction) {
      return reactions.find((reaction) => reaction.identity === eventId)
    }

    return
  }, [reactions, eventId, type])
  const selectedQuery = React.useMemo(() => {
    if (queries && type === STATEMENT_PARSING_INFO_TYPE.Query) {
      return queries.find((reaction) => reaction.identity === eventId)
    }

    return
  }, [queries, type, eventId])

  const selectedEvent = React.useMemo(() => {
    if (selectedCommand) {
      return selectedCommand
    }
    if (selectedReaction) {
      return selectedReaction
    }
    if (selectedQuery) {
      return selectedQuery
    }

    return
  }, [selectedCommand, selectedQuery, selectedReaction])

  function handleClick(event: React.MouseEvent<HTMLElement>) {
    setAnchorEl(event.currentTarget)
  }

  function handleClose() {
    setAnchorEl(null)
  }

  function handleMenuSelect(event: React.MouseEvent<HTMLElement>) {
    const link = event.currentTarget.dataset.link
    if (link) {
      navigate(link)
      handleClose()
    } else {
      handleClose()
    }
  }

  return (
    <>
      <ButtonGroup aria-label="Medium-sized button group">
        {isCodeGenerationAllowed && (
          <Tooltip title="Generate Code">
            <IconButton
              aria-label="Generate Code"
              onClick={handleMenuSelect}
              data-link={linkPublish}
              disabled={!linkPublish}
            >
              <PublishIcon />
            </IconButton>
          </Tooltip>
        )}

        <Tooltip title="Edit">
          <IconButton
            aria-label="Edit"
            onClick={handleMenuSelect}
            data-link={linkEdit}
            disabled={!linkEdit}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>

        <Tooltip title="Delete">
          <IconButton
            aria-label="Delete"
            onClick={handleMenuSelect}
            data-link={linkDelete}
            disabled={!linkDelete}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </ButtonGroup>
    </>
  )
}
