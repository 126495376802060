import ReplayIcon from '@mui/icons-material/Replay'
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined'
import {
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
  type SxProps,
  type Theme,
} from '@mui/material'
import type { MouseEvent } from 'react'
import type { NodeStatement } from '~/services/Process.types'

export type StepHeaderTitleProps = Pick<
  NodeStatement,
  'isEditMutationError' | 'isEditable' | 'isExpanded' | 'isFetching'
> & {
  /** The typography wrapper class names. */
  className?: string
  /** Event handler for `edit mutation retry button` click. */
  onEditMutationRetry?: () => void
  /** The typography wrapper styles. */
  style?: SxProps<Theme>
  /** The title text. */
  title?: string | JSX.Element
}

/**
 * The step header title component.
 */
export const StepHeaderTitle = (props: StepHeaderTitleProps) => {
  const {
    className,
    isEditable,
    isEditMutationError,
    isExpanded,
    isFetching,
    onEditMutationRetry,
    style,
    title,
  } = props

  // Retry edit mutation.
  const handleRetryClick = (
    e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
  ) => {
    e.stopPropagation()
    onEditMutationRetry?.()
  }

  return (
    <Typography className={className} sx={style}>
      {!isEditable && !isExpanded && isFetching && (
        <CircularProgress
          className="mr-1 text-primary"
          size={'1rem'}
          sx={{ minWidth: '1rem', minHeight: '1rem' }}
        />
      )}

      {isEditMutationError && (
        <Tooltip arrow placement="top" title="Something went wrong.">
          <ReportProblemOutlinedIcon fontSize="small" />
        </Tooltip>
      )}

      {title}

      {isEditMutationError && (
        <IconButton
          className="absolute bottom-[-12px] right-[-12px] border-2 border-solid border-event bg-background-paper p-[5px] hover:border-secondary"
          onClick={handleRetryClick}
          title="Retry"
        >
          <ReplayIcon sx={{ fontSize: 15 }} />
        </IconButton>
      )}
    </Typography>
  )
}
