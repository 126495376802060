import { AxiosResponse } from 'axios'
import { useMemo } from 'react'
import { useLoaderData } from 'react-router-dom'
import {
  FolderOrFileStructure,
  PropertyBinding,
  ResponseGetGenerationStrategy,
} from '~/services/GenerationStrategy.types'
import { MappingConditionItem } from '.'

const suggestions = {
  name: 'Suggestions',
  items: [
    {
      description: "Types 'ViewModel'",
      condition: "[Aggregates.Types.Family] == 'ViewModel'",
    },
    {
      description: 'Query that returns a collection',
      condition: '[Aggregates.Queries.Result.IsCollection]',
    },
    {
      description: 'Query that a single object',
      condition: '![Aggregates.Queries.Result.IsCollection]',
    },
  ] as MappingConditionItem[],
}

export const useMappingConditions = () => {
  const initialData = useLoaderData() as {
    strategy: Awaited<
      AxiosResponse<ResponseGetGenerationStrategy | null, unknown>
    >
  }

  const directoryItems = useMemo(
    () => initialData.strategy.data?.directoryItems ?? [],
    [initialData],
  )
  const userConditions = useMemo(
    () => extractUserMappingConditions(directoryItems),
    [initialData],
  )

  const userMappingConditionsItems = userConditions.length
    ? { name: 'Previously configured', items: [...userConditions] }
    : { name: '', items: [] }

  const conditions = userConditions.length
    ? [userMappingConditionsItems, suggestions]
    : [suggestions]

  return {
    conditions,
    allConditions: conditions.flatMap((group) => group.items),
  }
}

const extractUserMappingConditions = (
  directoryItems: FolderOrFileStructure[],
) => {
  const mapToMappingConditionItemList = (
    description: string,
    condition: string,
  ) => ({ condition, description }) as MappingConditionItem
  const extractPropertyBinding = (
    objectItem: PropertyBinding,
  ): MappingConditionItem[] => {
    const items: MappingConditionItem[] = []
    if (objectItem.mappingCondition) {
      items.push(mapToMappingConditionItemList('', objectItem.mappingCondition))
    }
    if (objectItem.propertyBindings) {
      items.push(
        ...objectItem.propertyBindings.flatMap((x) =>
          extractPropertyBinding(x).filter((x) => !!x),
        ),
      )
    }
    if (objectItem.children) {
      items.push(
        ...objectItem.children.flatMap((x) =>
          extractPropertyBinding(x).filter((x) => !!x),
        ),
      )
    }
    if (objectItem.content) {
      items.push(...extractPropertyBinding(objectItem.content))
    }
    return items
  }

  const extractMappingCondition = (
    objectItem: FolderOrFileStructure,
  ): MappingConditionItem[] => {
    const items: MappingConditionItem[] = []
    if (objectItem.mappingCondition) {
      items.push(mapToMappingConditionItemList('', objectItem.mappingCondition))
    }
    if (objectItem.propertyBindings) {
      items.push(
        ...objectItem.propertyBindings.flatMap((x) =>
          extractPropertyBinding(x).filter((x) => !!x),
        ),
      )
    }
    if (objectItem.fileBinding) {
      items.push(...extractPropertyBinding(objectItem.fileBinding))
    }
    if (objectItem.children) {
      items.push(
        ...objectItem.children.flatMap((x) =>
          extractMappingCondition(x).filter((x) => !!x),
        ),
      )
    }
    return items
  }

  return directoryItems
    .flatMap((x) => extractMappingCondition(x))
    .filter(
      (item, i, self) =>
        i === self.findIndex((t) => t.condition === item.condition) &&
        suggestions.items.findIndex((t) => item.condition === t.condition) ===
          -1,
    )
}
