import WrapTextIcon from '@mui/icons-material/WrapText'
import { Tooltip, type TooltipProps } from '@mui/material'
import { ActionIcon as CommandIcon } from '~/components/Icons/ActionIcon'
import { ReactionIcon } from '~/components/Icons/ReactionIcon'

const HEIGHT = 15
const WIDTH = 15

type ServiceActionIconProp = {
  /** The action type. */
  type: string
}

/**
 * Provides the action icon service node.
 */
export const ServiceActionIcon = (props: ServiceActionIconProp) => {
  const { type } = props

  if (type === 'Command')
    return (
      <ActionIconTooltip title={type}>
        <div className="flex">
          <CommandIcon fontSize="custom" height={HEIGHT} width={WIDTH} />
        </div>
      </ActionIconTooltip>
    )

  if (type === 'Reaction')
    return (
      <ActionIconTooltip title={type}>
        <div className="flex">
          <ReactionIcon height={HEIGHT} width={WIDTH} />
        </div>
      </ActionIconTooltip>
    )

  if (type === 'Query')
    return (
      <ActionIconTooltip title={type}>
        <WrapTextIcon
          style={{
            height: HEIGHT,
            width: WIDTH,
          }}
        />
      </ActionIconTooltip>
    )

  if (type === 'Event')
    return (
      <ActionIconTooltip title={type}>
        <div className="flex">
          <ReactionIcon height={HEIGHT} width={WIDTH} />
        </div>
      </ActionIconTooltip>
    )

  return null
}

function ActionIconTooltip(props: TooltipProps) {
  const { children, title } = props

  return (
    <Tooltip arrow placement="left" title={title}>
      {children}
    </Tooltip>
  )
}
