import AddIcon from '@mui/icons-material/Add'
import { Button, Stack, Typography } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { useEffect, useMemo, type MouseEvent } from 'react'
import { FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { Form, useActionData, useLoaderData, useParams } from 'react-router-dom'
import { ButtonAutoGenerate } from '~/components/ButtonAutoGenerate'
import { Row } from '~/components/Row'
import { FormActionName } from '~/pages/developer/components/FormActionName/index'
import { FormAttributes } from '~/pages/developer/components/FormAttributes'
import { FormFooter } from '~/pages/developer/components/FormFooter'
import { FormQueries } from '~/pages/developer/components/FormQueries'
import { DeveloperChildrenParams } from '~/routes/developer/routes.types'
import { queryDevelopment } from '~/services/Development'
import { Attribute, DomainByPlatformId } from '~/services/Development.types'
import { readModelByAggregate } from '~/utils/transform'
import { useAggregateActionForm } from '../hooks/useAggregateActionForm'
import { renamePropertiesToAttributes } from '../utils'

export function DeveloperEditReadModel() {
  const dataAutoGeneratedAttributes = useActionData() as {
    formName: string
    attributes: Attribute[]
  }
  const params = useParams<DeveloperChildrenParams & { readModelId: GUID }>()
  const initialData = useLoaderData() as Awaited<
    AxiosResponse<DomainByPlatformId | null, unknown>
  >
  const { data } = useQuery({
    ...queryDevelopment(params.platformId),
    initialData,
    select: (response) => response.data,
  })

  const queries = readModelByAggregate(data)

  const selectedReadModel = useMemo(
    () =>
      queries.find((readModel) => readModel.identity === params.readModelId),
    [params.readModelId, queries],
  )

  const methods = useForm({
    defaultValues: {
      action: {
        name: selectedReadModel?.name,
        attributes: renamePropertiesToAttributes(
          dataAutoGeneratedAttributes?.attributes ||
            selectedReadModel?.attributes,
        ),
      },
      queries: selectedReadModel?.queries.map((query) => ({
        name: query.name,
        attributes: renamePropertiesToAttributes(query.parameters),
      })),
    },
  })

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: 'queries',
  })

  // Hooks.
  const { formProps, submitButtonRef } = useAggregateActionForm()

  const handleAddQuery = () => {
    append({
      name: '',
      attributes: [],
    })
  }

  const handleRemoveQuery = (event: MouseEvent<HTMLButtonElement>) => {
    const index = event.currentTarget.dataset.index
    if (index) {
      remove(Number(index))
    }
  }

  useEffect(() => {
    if (
      dataAutoGeneratedAttributes &&
      dataAutoGeneratedAttributes?.formName === 'readModel' &&
      dataAutoGeneratedAttributes?.attributes.length > 0
    ) {
      methods.setValue(
        'action.attributes',
        renamePropertiesToAttributes(dataAutoGeneratedAttributes?.attributes),
      )
    }
  }, [dataAutoGeneratedAttributes, methods])

  return (
    <Stack gap={2} sx={{ pb: 6 }}>
      <FormProvider {...methods}>
        <Form method="post" id="form-aggregate-edit-readmodel" {...formProps}>
          <Stack gap={2}>
            <FormActionName
              inputLabel="Response type"
              inputName="readModel.name"
              defaultValue={selectedReadModel?.name}
            >
              <FormAttributes
                aggregateId={selectedReadModel?.aggregateId}
                autoGenerateComponent={<ButtonAutoGenerate name="readModel." />}
                formName="action"
              />
            </FormActionName>

            <Row className="gap-2">
              <Typography>Queries ({fields.length})</Typography>

              <Button
                variant="outlined"
                onClick={handleAddQuery}
                startIcon={<AddIcon />}
                size="small"
              >
                Add
              </Button>
            </Row>

            {fields.map((field, index) => (
              <FormQueries
                key={field.id}
                defaultValue={field.name || ''}
                handleRemoveQuery={handleRemoveQuery}
                isRemoveDisabled={fields.length === 1}
                index={index}
                inputName={`queries.${index}.name`}
                handleAddQuery={handleAddQuery}
                isLastItem={index === fields.length - 1}
              >
                <FormAttributes
                  aggregateId={selectedReadModel?.aggregateId}
                  autoGenerateComponent={
                    <ButtonAutoGenerate name={`queries.${index}.`} />
                  }
                  formLabel="Parameters"
                  formName={`queries.${index}`}
                  inputLabel="Parameters"
                />
              </FormQueries>
            ))}
          </Stack>

          <FormFooter submitButtonRef={submitButtonRef} />
        </Form>
      </FormProvider>
    </Stack>
  )
}
