import {
  Box,
  Button,
  Drawer,
  List,
  ListItemButton,
  ListItemText,
  Stack,
} from '@mui/material'
import Typography from '@mui/material/Typography'
import { useQuery } from '@tanstack/react-query'
import { ReactFlowProvider } from '@xyflow/react'
import '@xyflow/react/dist/style.css'
import type { AxiosResponse } from 'axios'
import {
  Outlet,
  generatePath,
  useLoaderData,
  useNavigate,
  useOutlet,
} from 'react-router-dom'
import { Row } from '~/components/Row'
import { Text } from '~/components/Text'
import { useModalTitleByLocation } from '~/hooks/useModalTitleByLocation'
import { DeveloperRoutesEnum } from '~/models/enums/routes/DeveloperRoutesEnum'
import { useBizDevResources } from '~/routes/business-developer/biz-dev-utils/useBizDevResources'
import {
  queryDevelopment,
  queryDevelopmentDomainsForCommands,
} from '~/services/Development'
import type {
  DomainByPlatformId,
  DomainForCommand,
} from '~/services/Development.types'
import { queryPlatformTeam } from '~/services/PlatformTeam'
import type { PlatformByOrganisation } from '~/services/PlatformTeam.types'
import { AggregateInfo } from './components/AggregateInfo'
import { ServiceMap } from './components/ServiceMap'
import { useDeveloperRouteParams } from './hooks/useDeveloperRouteParams'

export function Developer() {
  // React Router Dom.
  // cannot use typeof loader below because the loader redirects
  // and that causes its return to also be a Response, which we don't cover here
  const initialData = useLoaderData() as {
    development: Awaited<AxiosResponse<DomainByPlatformId | null, unknown>>
    platform: Awaited<AxiosResponse<PlatformByOrganisation | null, unknown>>
    organisationId: string
    domains: Awaited<AxiosResponse<DomainForCommand | null, unknown>>
  }

  const navigate = useNavigate()
  const outlet = useOutlet()

  // Base resources.
  const {
    // Pathnames.
    isCommandPage,
    isCommitsHistoryPage,
    isEditPage,
    isPlatformSetup,
    isQueryPage,
    isReactionPage,
    isReadModelPage,
    isTypePage,
    // URL params.
    aggregateId,
    boundedContext,
    commandId,
    organisationId,
    platformId,
    reactionId,
    readModelId,
    queryId,
    typeId,
    // Constants.
    dataId,
    dataType,
    eventType,
    urlAggregate,
    urlType,
  } = useBizDevResources()

  // React Query.
  const { data: dataDevelopment } = useQuery({
    ...queryDevelopment(platformId),
    initialData: initialData.development,
    select: (response) => response.data,
  })

  const { data: dataPlatform } = useQuery({
    ...queryPlatformTeam(initialData.organisationId),
    initialData: initialData.platform,
    select: (response) => response.data,
  })

  const { data: dataDomains } = useQuery({
    ...queryDevelopmentDomainsForCommands(platformId),
    initialData: initialData.domains,
    select: (response) => response.data,
  })

  // Hooks.
  const { modalTitle, modalTitleIcon } = useModalTitleByLocation()

  const { isValidAggregate, isValidBoundedContext } = useDeveloperRouteParams()

  // Constants.
  const selectedBoundedContext =
    aggregateId !== 'add-service'
      ? dataDevelopment?.boundedContexts.filter((context) =>
          context.aggregates.find(
            (aggregate) => aggregate.identity === aggregateId,
          ),
        )
      : undefined

  const menuSelected =
    selectedBoundedContext && selectedBoundedContext[0]
      ? selectedBoundedContext[0].name
      : ''

  const selectedAggregate = selectedBoundedContext
    ? selectedBoundedContext[0]?.aggregates.filter(
        (aggregate) => aggregate.identity === aggregateId,
      )
    : undefined

  // This will determine the number of rows to organize the grid layout.
  // The `1` accounts for the `ServiceMap`, which is always rendered.
  const gridLayoutRows = 1 + (selectedAggregate?.length || 0)

  // Methods.
  function toggleDrawer() {
    let basePath = `/${organisationId}/${platformId}/developer`
    if (isValidBoundedContext) basePath = `${basePath}/${boundedContext}`
    if (isValidAggregate && !urlAggregate && !urlType)
      basePath = `${basePath}/${aggregateId}`
    if (!!urlAggregate) basePath = `${basePath}?aggregate=${urlAggregate}`
    if (!!urlType) basePath = `${basePath}?type=${urlType}`

    navigate(basePath)
  }

  function handleNavigate(event: EventFor<'div', 'onClick'>) {
    const dataset = event?.currentTarget.dataset

    if (dataset?.eventId) {
      let path = ''

      if (dataset.eventType === 'query')
        path = generatePath(DeveloperRoutesEnum.EDIT_QUERY, {
          aggregateId,
          boundedContext,
          organisationId,
          platformId,
          queryId: dataset.eventId,
        })

      if (dataset.eventType === 'readModel')
        path = generatePath(DeveloperRoutesEnum.EDIT_READ_MODEL, {
          aggregateId,
          boundedContext,
          organisationId,
          platformId,
          readModelId: dataset.eventId,
        })

      if (dataset.eventType === 'command')
        path = generatePath(DeveloperRoutesEnum.EDIT_COMMAND, {
          aggregateId,
          boundedContext,
          commandId: dataset.eventId,
          organisationId,
          platformId,
        })

      if (dataset.eventType === 'reaction')
        path = generatePath(DeveloperRoutesEnum.EDIT_REACTION, {
          aggregateId,
          boundedContext,
          organisationId,
          platformId,
          reactionId: dataset.eventId,
        })

      if (dataset.eventType === 'type')
        path = generatePath(DeveloperRoutesEnum.EDIT_TYPE, {
          aggregateId,
          boundedContext,
          organisationId,
          platformId,
          typeId: dataset.eventId,
        })

      if (dataset.eventType === 'aggregate')
        path = generatePath(DeveloperRoutesEnum.EDIT_AGGREGATE, {
          aggregateId,
          boundedContext,
          organisationId,
          platformId,
        })

      // Set type search param.
      if (!!urlType) path = `${path}?type=${urlType}`

      navigate(path)
    }
  }

  const handleNavigateToHistory = () => {
    let path = ''

    if (eventType === 'commands')
      path = generatePath(DeveloperRoutesEnum.EDIT_COMMAND_COMMITS_HISTORY, {
        aggregateId,
        boundedContext,
        commandId,
        organisationId,
        platformId,
      })

    if (eventType === 'reactions')
      path = generatePath(DeveloperRoutesEnum.EDIT_REACTION_COMMITS_HISTORY, {
        aggregateId,
        boundedContext,
        organisationId,
        platformId,
        reactionId,
      })

    if (eventType === 'readModels')
      path = generatePath(DeveloperRoutesEnum.EDIT_READ_MODEL_COMMITS_HISTORY, {
        aggregateId,
        boundedContext,
        organisationId,
        platformId,
        readModelId,
      })

    if (eventType === 'queries')
      return navigate(
        generatePath(DeveloperRoutesEnum.EDIT_COMMIT_HISTORY_QUERY, {
          aggregateId,
          boundedContext,
          organisationId,
          platformId,
          queryId,
        }),
      )

    if (eventType === 'types')
      path = generatePath(DeveloperRoutesEnum.EDIT_TYPE_COMMITS_HISTORY, {
        aggregateId,
        boundedContext,
        organisationId,
        platformId,
        typeId,
      })

    if (eventType === 'aggregate')
      path = generatePath(DeveloperRoutesEnum.EDIT_AGGREATE_COMMITS_HISTORY, {
        aggregateId,
        boundedContext,
        organisationId,
        platformId,
      })

    // Set aggregate/type search param.
    if (!!aggregateId) path = `${path}?aggregate=${urlAggregate}`
    if (!!urlType) path = `${path}?type=${urlType}`

    navigate(path)
  }

  return (
    <div className="relative flex flex-1 justify-center">
      {!!dataDevelopment?.boundedContexts?.length && (
        <ReactFlowProvider>
          <div
            className="grid-row grid flex-grow grid-rows-[1fr_45%]"
            style={{
              gridTemplateRows:
                gridLayoutRows > 1 ? 'minmax(0, 1fr) 45%' : 'minmax(0, 1fr)',
            }}
          >
            <ServiceMap />

            {selectedBoundedContext &&
              selectedAggregate &&
              selectedAggregate.map((aggregate) => (
                <AggregateInfo
                  aggregate={aggregate}
                  menuSelected={menuSelected}
                  key={aggregate.identity}
                  platformConfigured={dataPlatform}
                  domains={dataDomains}
                />
              ))}
          </div>
        </ReactFlowProvider>
      )}

      {isPlatformSetup ? (
        <Outlet />
      ) : isEditPage || isCommitsHistoryPage ? (
        <Drawer
          anchor="right"
          disablePortal
          onClose={toggleDrawer}
          open={!!outlet}
        >
          <Box className="flex h-full min-w-[550px] flex-col md:w-screen md:min-w-fit">
            <Stack
              alignItems={'center'}
              className="p-6 shadow-appBar"
              direction={'row'}
              justifyContent={'space-between'}
            >
              <Stack direction={'row'} alignItems={'center'} gap={1}>
                {modalTitleIcon}

                <Typography variant="h6">{modalTitle}</Typography>
              </Stack>

              <Button variant="outlined" onClick={toggleDrawer}>
                Close
              </Button>
            </Stack>

            <Row className="flex-1 overflow-hidden">
              <div className="flex min-w-[260px] flex-col bg-white px-6 pb-4 pt-6 shadow-menuLeft">
                <Text className="text-xs uppercase text-slate-500">Action</Text>

                <List>
                  <ListItemButton
                    className="mb-1"
                    data-event-id={dataId}
                    data-event-type={dataType}
                    onClick={handleNavigate}
                    selected={
                      (isCommandPage ||
                        isQueryPage ||
                        isReactionPage ||
                        isReadModelPage ||
                        isTypePage) &&
                      !isCommitsHistoryPage
                    }
                  >
                    <ListItemText primary="Edit action" />
                  </ListItemButton>

                  <ListItemButton
                    onClick={handleNavigateToHistory}
                    selected={isCommitsHistoryPage}
                  >
                    <ListItemText primary="History" />
                  </ListItemButton>
                </List>
              </div>

              <div className="min-w-[550px] max-w-[550px] overflow-y-scroll p-6">
                <Outlet />
              </div>
            </Row>
          </Box>
        </Drawer>
      ) : (
        <Drawer
          anchor="right"
          open={!!outlet}
          onClose={toggleDrawer}
          disablePortal
        >
          <Box p={3} className="min-w-[550px] md:w-screen md:min-w-fit">
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <Stack direction={'row'} mb={2} alignItems={'center'} gap={1}>
                {modalTitleIcon}
                <Typography variant="h6">{modalTitle}</Typography>
              </Stack>

              <Button
                variant="outlined"
                onClick={toggleDrawer}
                className="mb-5"
              >
                Close
              </Button>
            </Stack>

            <Outlet />
          </Box>
        </Drawer>
      )}
    </div>
  )
}
