/** @type {import('tailwindcss').Config} */
import { extendTailwindMerge } from 'tailwind-merge'
import type { Config } from 'tailwindcss'
import plugin from 'tailwindcss/plugin'

const config: Config = {
  darkMode: 'class',
  corePlugins: {
    preflight: false,
  },
  important: '#app',
  content: ['./index.html', './src/**/*.{js,ts,jsx,tsx}'],
  theme: {
    extend: {
      colors: {
        primary: {
          50: '#EEEFFC',
          100: '#CECFF8',
          200: '#9DA0F2',
          300: '#6D71EB',
          400: '#3C42E5',
          500: '#0C13DF',
          600: '#090FB2',
          DEFAULT: '#070B85',
          800: '#040759',
          900: '#02032C',
          950: '#01011A',

          // DEFAULT: '#070B85',
          // alt: '#080D97',
          // lighter: '#A3AAE3',
          // border: '#A6A6E5',
          // input: '#3135A8',
          disabled: '#9EA0CE',
          dark: {
            // DEFAULT: '#97F0E2',
            contrastText: '#03231E',
          },
          new: '#394ACF',
        },
        secondary: {
          50: '#F2FDFB',
          100: '#D9FAF5',
          200: '#B4F5EB',
          300: '#8FF1E1',
          400: '#6AECD7',
          500: '#45E8CD',
          DEFAULT: '#37B9A4',
          700: '#298B7B',
          800: '#1B5C51',
          900: '#0D2E28',
          950: '#071B18',

          // DEFAULT: '#32AD9B',
          // dark: {
          //   DEFAULT: '#54FFE2',
          // },
          // alt: '#B6F1E6',
        },

        tertiary: {
          50: '#F6F7FC',
          100: '#E6E7F7',
          200: '#CDD0EF',
          300: '#B4B9E7',
          400: '#9BA2DF',
          500: '#828BD7',
          DEFAULT: '#686FAC',
          700: '#4E5381',
          800: '#333755',
          900: '#191B2A',
          950: '#0F1019',
        },

        font: {
          DEFAULT: '#292C6C',
          muted: '#546E7A',
          contrast: '#FFFFFF',
          lightGray: '#7E80A4',
          dark: {
            DEFAULT: '#FFFFFF',
          },
        },
        paper: {
          DEFAULT: '#FFFFFF',
          dark: {
            DEFAULT: '#272B39',
          },
        },
        background: {
          DEFAULT: '#FBFCFF',
          paper: '#FFFFFF',
          highlight: '#F4F5FF',
          bright: '#FAFAFF',
          panel: '#FFFFFF',
          dark: {
            DEFAULT: '#1E1F2C',
            paper: '#272B39',
            highlight: '#1E1F2C',
            bright: '#121212',
            panel: '#474B57',
          },
          glass: 'rgba(255, 255, 255, 0.3)',
          hover: 'rgba(24,27,101,0.1)',
          transparent: 'rgba(0, 0, 0, 0.0)',
          chip: '#E5E7FC',
          business: '#F2F5FE',
        },
        command: {
          DEFAULT: '#0a2342',
          light: '#FFFFFF',
        },
        /*event: {
          DEFAULT: '#ff9800',
          light: '#fff3e0',
        },*/
        event: {
          DEFAULT: '#ff6b6b',
          light: '#ffe6e6',
        },
        reaction: {
          DEFAULT: '#175467',
          light: '#FFFFFF',
        },
        raised: {
          DEFAULT: '#23AFDB',
        },
        divider: {
          light: '#DEE1F8',
        },
        node: {
          border: '#A3AAE3',
          red: '#FF5252',
          green: 'rgba(16,200,171,1)',
          yellow: '#ffee58',
        },
        success: {
          DEFAULT: '#4CAF50',
        },
      },
      fontSize: {
        '2xs': '0.625rem',
        10: '0.625rem',
        11: '0.6875rem',
        17: '1.0625rem',
      },
      boxShadow: {
        1: '3px 3px 3px 0 rgba(24,27,101,0.1)',
        2: 'rgba(24, 27, 101, 0.1) 3px 3px 3px, rgba(24, 27, 101, 0.03) -3px -3px 3px',
        3: `0px 0px 10px 1px rgba(24,27,101,0.1)`,
        4: `0px 0px 10px 1px rgba(16,200,171,1)`,
        5: `0px 0px 10px 1px #D81B60`,
        6: `0px 0px 10px 1px #ffee58`,
        appBar: '4px 0px 8px 0px rgba(0, 2, 70, 0.14)',
        menuLeft: '5px 0px 5px -2px #e5e5e5',
      },
      dropShadow: {
        1: '3px 3px 3px 0 rgba(24,27,101,0.1)',
      },
      textShadow: {
        DEFAULT:
          '-1px -1px 0 #FFFFFF, 1px -1px 0 #FFFFFF, -1px 1px 0 #FFFFFF, 1px 1px 0 #FFFFFF, 3px 3px 3px rgba(24,27,101,0.1)',
      },
      animation: {
        breathNodeBoxShadow: 'breathNodeBoxShadow 2s infinite',
        ellipsis: 'ellipsis steps(1,end) 1s infinite',
      },
      keyframes: {
        breathNodeBoxShadow: {
          '0%, 100%': {
            boxShadow: `0px 0px 10px 1px rgba(16,200,171,1)`,
          },
          '50%': {
            boxShadow: `0px 0px 80px 10px rgba(16,200,171,1)`,
          },
        },
        ellipsis: {
          '0%': { content: '""' },
          '25%': { content: '"."' },
          '50%': { content: '".."' },
          '75%': { content: '"..."' },
          '100%': { content: '""' },
        },
      },
    },
    screens: {
      '2xl': { max: '1535px' },
      xl: { max: '1279px' },
      lg: { max: '1023px' },
      md: { max: '767px' },
      sm: { max: '639px' },
    },
    gridTemplateColumns: {
      contentWithSideNav: '2fr minmax(250px, auto) minmax(600px, auto) 2fr',
    },
  },
  plugins: [
    plugin(({ matchUtilities, theme }) => {
      matchUtilities(
        {
          'text-shadow': (value) => ({
            textShadow: value,
          }),
        },
        { values: theme('textShadow') },
      )
    }),
    ({ addVariant, e }: any) => {
      addVariant('not-disabled', ({ modifySelectors, separator }: any) => {
        modifySelectors(
          ({ className }: any) =>
            `.${e(`not-disabled${separator}${className}`)}:not(:disabled)`,
        )
      })
    },
  ],
}

export const twMerge = extendTailwindMerge({
  extend: {
    classGroups: {
      'font-size': Object.keys(config?.theme?.extend?.fontSize || []).map(
        (key) => `text-${key}`,
      ),
    },
  },
})

export default config
