import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react'
import AddIcon from '@mui/icons-material/Add'
import {
  Box,
  Button,
  GlobalStyles,
  List,
  ListItemButton,
  ListItemText,
  Paper,
  Stack,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { twMerge } from '^/tailwind.config'
import { AxiosResponse } from 'axios'
import { useEffect, type MouseEvent } from 'react'
import {
  Outlet,
  generatePath,
  useLoaderData,
  useNavigate,
  useNavigation,
  useParams,
} from 'react-router-dom'
import TopBarProgress from 'react-topbar-progress-indicator'
import { ConditionalWrapper } from '~/components/ConditionalWrapper'
import { Header } from '~/components/Header'
import { BoundedContextEnum } from '~/models/enums/BoundedContextEnum'
import { DeveloperRoutesEnum } from '~/models/enums/routes/DeveloperRoutesEnum'
import { MenuWrapper } from '~/pages/developer/components/MenuWrapper'
import { Unauthenticated } from '~/pages/developer/components/Unauthenticated'
import { DeveloperChildrenParams } from '~/routes/developer/routes.types'
import { queryDevelopment } from '~/services/Development'
import { DomainByPlatformId } from '~/services/Development.types'
import { useGlobalStore } from '~/store'
import { ButtonAddAggregate } from '../components/ButtonAddAggregate/ButtonAddAggregate'
import { useDeveloperRouteParams } from '../hooks/useDeveloperRouteParams'

const globalStyles = (
  <GlobalStyles
    styles={{
      '#root': {
        display: 'block',
        height: '100svh',
      },
      strong: {
        fontWeight: 500,
      },
    }}
  />
)

TopBarProgress.config({
  barColors: {
    '0': 'rgba(16,200,171,1)',
    '1.0': '#020890',
  },
  shadowBlur: 5,
})

export function DeveloperLayout() {
  // React Router Dom.
  const params = useParams<DeveloperChildrenParams>()
  const {
    aggregateId,
    boundedContext = '',
    organisationId = '',
    platformId = '',
  } = params

  const initialData = useLoaderData() as Awaited<
    AxiosResponse<DomainByPlatformId | null, unknown>
  >

  const navigation = useNavigation()

  const navigate = useNavigate()

  // Global Store.
  const colorMode = useGlobalStore((state) => state.colorMode)
  const isMenuOpen = useGlobalStore((state) => state.isMenuOpen)
  const toggleIsMenuOpen = useGlobalStore((state) => state.toggleIsMenuOpen)

  // React Query.
  const { data } = useQuery({
    ...queryDevelopment(platformId),
    initialData,
    select: (response) => response.data,
  })

  // A list of case insensitive bounded contexts.
  const boundedContextsList = [
    ...new Set(
      data?.boundedContexts.map((item) =>
        item.name.trim()
          ? item.name.toLowerCase()
          : BoundedContextEnum.NO_BOUNDED_CONTEXT,
      ),
    ),
  ].sort((a, b) => {
    // Sort alphabetically with `no bounded context` at the end.
    if (a === BoundedContextEnum.NO_BOUNDED_CONTEXT) return 1
    if (b === BoundedContextEnum.NO_BOUNDED_CONTEXT) return -1
    return a.localeCompare(b)
  })

  // Hooks.
  const styleMatches = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up('md'),
  )

  const { isValidBoundedContext } = useDeveloperRouteParams()

  // Lifecycle.
  // Set a default bounded context, when there is an invalid one.
  useEffect(() => {
    // If it does not exist navigate to the first one existing bounded context.
    if (!isValidBoundedContext)
      navigate(
        generatePath(DeveloperRoutesEnum.DEVELOPER_BOUNDED_CONTEXT, {
          boundedContext: boundedContextsList[0] as string,
          organisationId,
          platformId,
        }),
      )
  }, [boundedContext, data])

  // Methods.
  const handleBoundedContextClick = (boundedContext: string) => {
    navigate(
      generatePath(DeveloperRoutesEnum.DEVELOPER_BOUNDED_CONTEXT, {
        boundedContext,
        organisationId,
        platformId,
      }),
    )
  }

  const handleAddServiceClick = (
    e: MouseEvent<HTMLButtonElement>,
    boundedContext: string,
  ) => {
    e.stopPropagation()

    const basePath = generatePath(
      DeveloperRoutesEnum.DEVELOPER_BOUNDED_CONTEXT,
      {
        boundedContext,
        organisationId,
        platformId,
      },
    )
    const path = `${basePath}/${aggregateId ? aggregateId : 'add-service'}/details`

    navigate(path)
  }

  return (
    <>
      {navigation.state === 'loading' && <TopBarProgress />}

      <AuthenticatedTemplate>
        <Header />

        <Stack
          className="top-[65px] md:top-[105px]"
          sx={{
            bottom: 0,
            left: 0,
            right: 0,
            position: 'absolute',
          }}
        >
          {globalStyles}

          <Stack
            sx={{ width: 1, height: 'calc(100svh - 65px)' }}
            direction="row"
          >
            <ConditionalWrapper
              condition={!styleMatches}
              wrapper={(children) => (
                <MenuWrapper
                  isMenuOpen={isMenuOpen}
                  toggleIsMenuOpen={toggleIsMenuOpen}
                >
                  {children}
                </MenuWrapper>
              )}
            >
              <Stack
                sx={{
                  width: '100%',
                  maxWidth: '270px',
                  boxShadow:
                    colorMode === 'dark'
                      ? '5px 0 5px -2px #272b39'
                      : '5px 0px 5px -2px #e5e5e5',

                  borderRight: colorMode === 'dark' ? 0 : '1px solid #e0e1f2',
                  overflowY: 'auto',
                  pb: 2,
                }}
                component="aside"
              >
                <Paper
                  elevation={colorMode === 'dark' ? 1 : 0}
                  sx={{
                    width: '100%',
                    p: 2,
                    borderRadius: 0,
                    flexGrow: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      sx={{
                        color: '#76779C',
                        fontWeight: 300,
                      }}
                      variant="subtitle2"
                    >
                      Services
                    </Typography>

                    <ButtonAddAggregate />
                  </Box>

                  <List>
                    {boundedContextsList?.map((context) => (
                      <ListItemButton
                        className="gap-2"
                        key={context}
                        onClick={() => handleBoundedContextClick(context)}
                        selected={context === boundedContext}
                        sx={{
                          marginTop: 1,
                          border: '1px #ffffff solid',
                          ':hover': {
                            backgroundColor: '#DBFBF6',
                            border: '1px #6DD4C4 solid',
                            borderRadius: '4px',
                          },
                          '&.Mui-selected, &.Mui-selected:hover': {
                            backgroundColor: '#DBFBF6',
                            border: ' 1px #6DD4C4 solid',
                            borderRadius: '4px',
                          },
                        }}
                      >
                        <ListItemText
                          className="font-bold"
                          primary={context}
                          sx={{
                            '> span': {
                              fontSize: '0.8125rem',
                              textTransform: 'uppercase',
                              fontWeight: 600,
                            },
                          }}
                        />

                        <Tooltip arrow placement="right" title="Add service">
                          <Button
                            className={twMerge(
                              'invisible min-h-0 min-w-0 px-1',
                              context === boundedContext && 'visible',
                            )}
                            onClick={(e) =>
                              handleAddServiceClick(e, boundedContext)
                            }
                            variant="outlined"
                          >
                            <AddIcon />
                          </Button>
                        </Tooltip>
                      </ListItemButton>
                    ))}
                  </List>
                </Paper>
              </Stack>
            </ConditionalWrapper>

            <Outlet />
          </Stack>
        </Stack>
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <Unauthenticated />
      </UnauthenticatedTemplate>
    </>
  )
}
