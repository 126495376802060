import CodeIcon from '@mui/icons-material/Code'
import CodeOffIcon from '@mui/icons-material/CodeOff'
import { useEffect } from 'react'
import type { SelectedTextSectionsContextProps } from '~/contexts/SelectedTextSectionsContext'
import { useMergeTextSections } from '~/hooks/api/codeGenerationStrategy/useMergeTextSections'
import { useAppSnackbarContext } from '~/hooks/contexts/useAppSnackbarContext'
import type { ConfigPopperBaseProps } from '~/models/types/components/codeGenerationStrategy/ConfigPopperBaseProps'
import { MultipleNodesButtons } from './MultipleNodesButtons'
import { OneNodeButtons } from './OneNodeButtons'

export type ConfigPopperProps = ConfigPopperBaseProps &
  Pick<
    SelectedTextSectionsContextProps,
    'isMergeMode' | 'isMergeModeActivateByKeyboard'
  > & {
    /** The current file ID. */
    fileId?: GUID
    /** Callback handler for `activate merge mode` click. */
    onActivateMergeModeClick?: () => void
  }

/**
 * A popper config with options to:
 * - Open node properties;
 * - Delete node;
 * - Deselect child nodes;
 * - Merge selected nodes.
 */
export const ConfigPopper = (props: ConfigPopperProps) => {
  const {
    fileId,
    generationStrategyId,
    isMergeMode,
    isMergeModeActivateByKeyboard,
    node,
    onActivateMergeModeClick,
    onDeleteClick,
    onDeselectClick,
    selectedTextSections,
  } = props

  // Snackbar hook.
  const { closeAppSnackbar, openAppSnackbar } = useAppSnackbarContext()

  // Mutation to merge text sections.
  const {
    error,
    isPending,
    isSuccess,
    mutate,
    reset: resetMutation,
  } = useMergeTextSections({ generationStrategyId })

  // Lifecycle to set error snackbar props.
  useEffect(() => {
    if (error) {
      openAppSnackbar?.({
        alertProps: {
          children: error as string,
          severity: 'error',
        },
        snackbarProps: {
          anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
          autoHideDuration: 3000,
          onClose: (e, r) => {
            resetMutation?.()
            closeAppSnackbar?.(e, r)
          },
          open: true,
        },
      })
    }
  }, [error])

  // Deselect nodes when mutation is success.
  useEffect(() => {
    if (isSuccess) {
      onDeselectClick?.()
      resetMutation()
    }
  }, [isSuccess])

  // No content condition.
  if (!selectedTextSections?.selected?.length) return null

  // Merge with children nodes click handler.
  const handleMergeWithChildrenClick = () => {
    mutate({
      fileId,
      firstSectionId: selectedTextSections.selected?.[0],
      lastSectionId: selectedTextSections.selected?.[0],
      parentSectionId: selectedTextSections.parent,
    })
  }

  // Merge nodes click handler.
  const handleMergeClick = () => {
    mutate({
      fileId,
      firstSectionId: selectedTextSections?.selected?.[0],
      lastSectionId:
        selectedTextSections?.selected?.[
          selectedTextSections?.selected?.length - 1
        ],
      parentSectionId: selectedTextSections?.parent,
    })
  }

  // Constants.
  const mergeModeTxt = `${isMergeMode ? 'Deactivate' : 'Activate'} merge mode`
  const MergeModeIcon = isMergeMode ? CodeOffIcon : CodeIcon

  return (
    <div className="bg-transparent p-2">
      {/* {!isMergeModeActivateByKeyboard && (
        <Tooltip title={mergeModeTxt}>
          <Button
            className={twMerge('mr-2', buttonClasses)}
            onClick={onActivateMergeModeClick}
            name={mergeModeTxt}
          >
            <MergeModeIcon className={iconClasses} />
          </Button>
        </Tooltip>
      )} */}

      <OneNodeButtons
        node={node}
        onDeleteClick={onDeleteClick}
        onMergeWithChildrenClick={handleMergeWithChildrenClick}
        selectedTextSections={selectedTextSections}
      />

      <MultipleNodesButtons
        isMergePending={isPending}
        onDeselectClick={onDeselectClick}
        onMergeClick={handleMergeClick}
        selectedTextSections={selectedTextSections}
      />
    </div>
  )
}
