import { MarkerType, type Edge } from '@xyflow/react'
import { v4 as uuid } from 'uuid'
import type { ServiceAndEventsAction } from '~/models/types/developer/serviceMap/ServiceAndEventsType'
import type { ServiceMapThemePalette } from './reactFlowColors'

const arrowWidth = '2px'

export const createReactionEdge = (
  action: ServiceAndEventsAction,
  serviceOrTypeId: string,
  contextMap: Map<string, string>,
  colors: ServiceMapThemePalette,
): Edge | null => {
  const key = action.subscription?.from || ''
  const source = action.subscription?.typeId || contextMap.get(key)
  if (source == null) {
    return null
  }

  return {
    id: `${action.subscription?.domainEventName}-${action.name}-${uuid()}`,
    source: source,
    sourceHandle: `${source}--${action.subscription?.domainEventName}--source-handle`,
    target: serviceOrTypeId,
    targetHandle: `${action.subscription?.from}-${action.subscription?.domainEventName}-target-handle`,
    zIndex: 100,
    labelStyle: {
      fill: colors.subscribeEdgeColors.label,
      fontWeight: 'bold',
    },
    style: {
      stroke: colors.subscribeEdgeColors.line,
      strokeDasharray: 0,
      strokeWidth: arrowWidth,
    },
    markerEnd: {
      type: MarkerType.ArrowClosed,
      width: 15,
      height: 15,
      color: colors.subscribeEdgeColors.line,
    },
    type: 'smoothstep',
    data: { isReturningEdge: true },
  }
}
