import {
  Box,
  IconButton,
  Tooltip,
  Typography,
  type TooltipProps,
} from '@mui/material'
import { Handle, Position } from '@xyflow/react'
import { useRef, type MouseEvent } from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import EditIcon from '~/assets/icons/edit.svg?react'
import PublishIcon from '~/assets/icons/rocket.svg?react'
import TrashIcon from '~/assets/icons/trash.svg?react'
import DialogConfirmDelete, {
  type DialogConfirmDeleteHandle,
} from '~/components/dialogs/DialogConfirmDelete/DialogConfirmDelete'
import { useDeleteCommand } from '~/hooks/api/developer/useDeleteCommand'
import { useDeleteQuery } from '~/hooks/api/developer/useDeleteQuery'
import { useDeleteReaction } from '~/hooks/api/developer/useDeleteReaction'
import { useGetSubscriptionPlanCapabilities } from '~/hooks/useGetSubscriptionPlanCapabilities'
import { ServiceAndEventsActionTypeEnum } from '~/models/enums/developer/serviceMap/ServiceAndEventsActionTypeEnum'
import { DeveloperRoutesEnum } from '~/models/enums/routes/DeveloperRoutesEnum'
import { ServiceAndEventsAction } from '~/models/types/developer/serviceMap/ServiceAndEventsType'
import { ServiceActionIcon } from './ServiceActionIcon'

const TOOLTIP_PROPS = {
  arrow: true,
  placement: 'bottom',
} as TooltipProps

const ACTION_TYPE_COLORS = {
  [ServiceAndEventsActionTypeEnum.COMMAND]: 'bg-primary-50',
  [ServiceAndEventsActionTypeEnum.QUERY]: 'bg-secondary-50',
  [ServiceAndEventsActionTypeEnum.REACTION]: 'bg-secondary-50',
  [ServiceAndEventsActionTypeEnum.EVENT]: 'bg-accent-50',
}

type ServiceActionBoxProps = {
  /** The current action, eg: command, reaction, query. */
  action: ServiceAndEventsAction
  /**
   * The action type:
   * This type will drive the navigation,
   * the routes will be built based on this type.
   */
  actionType: ServiceAndEventsActionTypeEnum
  /** The ID of the aggregates that holds this actions. */
  aggregateId?: GUID
  /** The ID of the type that holds this actions. */
  typeId?: GUID
}

/**
 * The action box designed for the `service node` component.
 */
export const ServiceActionBox = (props: ServiceActionBoxProps) => {
  const { action, actionType, aggregateId = '', typeId } = props

  // Ref.
  const dialogConfirmDeleteRef = useRef<DialogConfirmDeleteHandle>(null)

  // React Router Dom.
  const navigate = useNavigate()

  const params = useParams()
  const { boundedContext = '', organisationId = '', platformId = '' } = params

  // Hooks.
  const { codeGeneration: isCodeGenerationAllowed } =
    useGetSubscriptionPlanCapabilities()

  const { isPending: isPendingDeleteCommand, mutate: mutateDeleteCommand } =
    useDeleteCommand()
  const { isPending: isPendingDeleteQuery, mutate: mutateDeleteQuery } =
    useDeleteQuery()
  const { isPending: isPendingDeleteReaction, mutate: mutateDeleteReaction } =
    useDeleteReaction()

  // Constants.
  const urlActionType = actionType.toLowerCase()

  // Methods.
  const handleGenerateCodeClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()

    navigate(
      `${generatePath(DeveloperRoutesEnum.DEVELOPER_AGGREGATE, {
        aggregateId,
        boundedContext,
        organisationId,
        platformId,
      })}/generate-code/${urlActionType}/${action.id}`,
    )
  }

  const handleEditClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()

    navigate(
      `${generatePath(DeveloperRoutesEnum.DEVELOPER_AGGREGATE, {
        aggregateId,
        boundedContext,
        organisationId,
        platformId,
      })}/edit-${urlActionType}/${action.id}`,
    )
  }

  const handleConfirmDeleteClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    dialogConfirmDeleteRef.current?.handleOpenDialog()
  }

  const handleDeleteClick = () => {
    const payload = {
      aggregateId,
      actionId: action.id,
    }

    if (actionType === ServiceAndEventsActionTypeEnum.COMMAND)
      return mutateDeleteCommand(payload)

    if (actionType === ServiceAndEventsActionTypeEnum.QUERY)
      return mutateDeleteQuery(payload)

    if (actionType === ServiceAndEventsActionTypeEnum.REACTION)
      return mutateDeleteReaction(payload)
  }

  return (
    <Box
      key={action.name}
      className={`mb-2 rounded ${ACTION_TYPE_COLORS[action.type]} p-2 pr-1`}
    >
      <div>
        <div className="relative flex items-center gap-1">
          <ServiceActionIcon type={action.type} />

          <Typography className="flex-1" variant="body2">
            {action.name}
          </Typography>

          <div className="flex self-start">
            {isCodeGenerationAllowed && (
              <Tooltip {...TOOLTIP_PROPS} title="Generate code">
                <IconButton
                  className="h-[25px] w-[25px] p-1"
                  onClick={handleGenerateCodeClick}
                >
                  <PublishIcon />
                </IconButton>
              </Tooltip>
            )}

            <Tooltip {...TOOLTIP_PROPS} title="Edit">
              <IconButton
                className="h-[25px] w-[25px] p-1"
                onClick={handleEditClick}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>

            <Tooltip {...TOOLTIP_PROPS} title="Delete">
              <IconButton
                className="h-[25px] w-[25px] p-1"
                onClick={handleConfirmDeleteClick}
              >
                <TrashIcon />
              </IconButton>
            </Tooltip>

            <DialogConfirmDelete
              isLoading={
                isPendingDeleteCommand ||
                isPendingDeleteQuery ||
                isPendingDeleteReaction
              }
              onConfirmClick={handleDeleteClick}
              ref={dialogConfirmDeleteRef}
            />
          </div>

          {action.type === ServiceAndEventsActionTypeEnum.REACTION && (
            <Handle
              id={`${action.subscription?.from}-${action.subscription?.domainEventName}-target-handle`}
              type="target"
              position={Position.Right}
              style={{
                right: '0px',
                visibility: 'hidden',
              }}
            />
          )}

          {action.type === ServiceAndEventsActionTypeEnum.QUERY && (
            <Handle
              id={`${typeId || aggregateId}-${action.id}-source-handle`}
              type="source"
              position={Position.Right}
              style={{
                right: '0px',
                visibility: 'hidden',
              }}
            />
          )}
        </div>

        {action.events.map((event) => (
          <div
            key={`${event.id}-${event.name}`}
            className="relative ml-4 mt-1 flex items-center gap-1"
          >
            <ServiceActionIcon type={ServiceAndEventsActionTypeEnum.EVENT} />

            <Typography variant="body2">{event.name}</Typography>

            <Handle
              id={`${typeId || aggregateId}--${event.name}--source-handle`}
              type="source"
              position={Position.Right}
              style={{
                right: '0px',
                visibility: 'hidden',
                background: '#555',
                width: '8px',
                height: '8px',
              }}
            />
          </div>
        ))}
      </div>
    </Box>
  )
}
