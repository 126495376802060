import AddIcon from '@mui/icons-material/Add'
import { IconButton, Tooltip } from '@mui/material'
import type { MouseEvent } from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { ServiceAndEventsActionTypeEnum } from '~/models/enums/developer/serviceMap/ServiceAndEventsActionTypeEnum'
import { DeveloperRoutesEnum } from '~/models/enums/routes/DeveloperRoutesEnum'
import { ServiceAndEventsAction } from '~/models/types/developer/serviceMap/ServiceAndEventsType'
import { ServiceActionBox } from '../ServiceActionBox/ServiceActionBox'

type ServiceActionSectionProps = {
  /** The current array of actions, eg: command, reaction, query. */
  actions?: ServiceAndEventsAction[]
  /**
   * The action type:
   * This type will drive the navigation,
   * the routes will be built based on this type.
   */
  actionType: ServiceAndEventsActionTypeEnum
  /** The ID of the aggregates that holds this actions. */
  aggregateId?: GUID
  /** The ID of the type that holds this actions. */
  typeId?: GUID
  /** The section label. */
  label?: string
  /** The tooltip title. */
  tooltipTitle?: string
}

/**
 * The action section designed for the `service node` component.
 */
export const ServiceActionSection = (props: ServiceActionSectionProps) => {
  const {
    actions,
    actionType,
    aggregateId = '',
    typeId,
    label,
    tooltipTitle,
  } = props

  // React Router Dom.
  const navigate = useNavigate()

  const params = useParams()
  const { boundedContext = '', organisationId = '', platformId = '' } = params

  // Constants.
  const urlActionType = actionType.toLowerCase()

  // Methods.
  const handleAddClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()

    const queryString = typeId ? `?context=${encodeURIComponent(typeId)}` : ''

    navigate(
      `${generatePath(DeveloperRoutesEnum.DEVELOPER_AGGREGATE, {
        aggregateId,
        boundedContext,
        organisationId,
        platformId,
      })}/add-${urlActionType}${queryString}`,
    )
  }

  return (
    <div>
      <h4 className="m-0 mb-1 flex items-center justify-between gap-2 text-sm">
        <span>{label}</span>

        <Tooltip arrow placement="top" title={tooltipTitle}>
          <IconButton
            className="h-[25px] w-[25px] p-2 text-primary"
            onClick={handleAddClick}
          >
            <AddIcon className="h-[18px] w-[18px]" />
          </IconButton>
        </Tooltip>
      </h4>

      {actions
        ?.filter((action) => action.type === actionType)
        ?.map((action) => (
          <ServiceActionBox
            action={action}
            actionType={actionType}
            aggregateId={aggregateId}
            typeId={typeId}
            key={action.id}
          />
        ))}
    </div>
  )
}
