import { PublicClientApplication } from '@azure/msal-browser'
import { QueryClient } from '@tanstack/react-query'
import { produce } from 'immer'
import { ActionFunctionArgs } from 'react-router-dom'
import invariant from 'tiny-invariant'
import { IS_DEV } from '~/config/constants'
import {
  getAttributesFromForm,
  getRaisedDomainEvents,
  redirectToDeveloperHomeOrAggregate,
} from '~/routes/developer/utils'
import {
  queryKeyDevelopment,
  queryKeyDevelopmentDomainsForCommands,
} from '~/services/Development'
import { queryKeyOrganisation } from '~/services/Discovery'
import { FREE_FEATURES_USAGE } from '~/services/Discovery.types'
import { STATEMENT_PARSING_INFO_TYPE } from '~/services/Process.types'
import {
  DevelopmentAggregateAPI,
  serviceDevelopmentAggregate,
} from '~/services/base'
import { getUserEmail } from '~/utils/getUserEmail'
import { getUserId } from '~/utils/getUserId'

export const actionDeveloperAddCommand =
  (queryClient: QueryClient, pca: PublicClientApplication) =>
  async ({ request, params }: ActionFunctionArgs) => {
    const userId = getUserId(pca)
    const email = getUserEmail(pca)
    const { aggregateId, platformId } = params

    invariant(aggregateId, 'No aggregateId found')
    invariant(platformId, 'No platformId found')
    invariant(userId, 'No userId found')

    // Get form data
    const formData = await request.formData()
    const commandName = formData.get('command.name')
    const commandActor = formData.get('command.actor')
    const commandTypeId = formData.get('command.typeId')
    const allForm = Object.fromEntries(formData)

    const actionAttributes = getAttributesFromForm({
      formEntries: allForm,
      kind: 'command.',
    })
    const raisedDomainEvents = getRaisedDomainEvents({ formEntries: allForm })

    // This will return early in case we are only auto generating attributes for commands
    // It will also return the data from it available at useActionData()
    const autoGenerateButton = Object.keys(allForm).find((key) =>
      key.includes('AutoGenerateAttributes'),
    )

    if (autoGenerateButton) {
      if (aggregateId && typeof aggregateId === 'string') {
        const isCommand = autoGenerateButton?.includes('command')
        const reqUrl = DevelopmentAggregateAPI.GenerateAttributes
        const data = {
          aggregateId: aggregateId?.toString() || '',
          entityType: isCommand
            ? STATEMENT_PARSING_INFO_TYPE.Command
            : 'DomainEvents',
          entityName:
            (isCommand
              ? commandName?.toString()
              : allForm[
                  autoGenerateButton?.replace(
                    'AutoGenerateAttributes',
                    'name',
                  ) || ''
                ]?.toString()) || '',
        }

        try {
          const res = await serviceDevelopmentAggregate.post(reqUrl, data)
          queryClient.setQueryData(
            queryKeyOrganisation(userId, email),
            (organisation: any) =>
              produce(organisation, (draft: any) => {
                draft.data.subscriptionPlan[
                  `${FREE_FEATURES_USAGE.ATTRIBUTE_AUTO_GENERATION}Remaining`
                ] =
                  organisation.data.subscriptionPlan[
                    `${FREE_FEATURES_USAGE.ATTRIBUTE_AUTO_GENERATION}Remaining`
                  ] - 1 || 0
              }),
          )
          return {
            formName: autoGenerateButton?.replace(
              '.AutoGenerateAttributes',
              '',
            ),
            attributes: res.data?.value?.attributes,
          }
        } catch (error) {
          if (IS_DEV) {
            console.log(error)
          }
          return []
        }
      }
    }
    // end of auto generation of attributes for commands

    const isCopyButtonPressed = Object.keys(allForm).find((key) =>
      key.includes('.copyButton'),
    )

    if (isCopyButtonPressed) {
      const attributes = getAttributesFromForm({
        formEntries: allForm,
        kind: isCopyButtonPressed.replace('copyButton', ''),
      })
      await navigator.clipboard.writeText(JSON.stringify(attributes))
      return null
    }

    // Validate errors
    const errors: GenericObj<string> = {}
    if (
      !commandName ||
      typeof commandName !== 'string' ||
      commandName.trim().length <= 1
    ) {
      errors.commandName = 'Required'
    }
    if (Object.keys(errors).length) {
      return errors
    }

    // Builds the data to send to server
    const postData = {
      aggregateId,
      commandName,
      commandActor,
      commandTypeId,
      domainEvents: raisedDomainEvents,
      attributes: actionAttributes,
    }

    // Setups and send to server
    const url = DevelopmentAggregateAPI.AddCommand
    const req = await serviceDevelopmentAggregate.post(url, postData)

    // Invalidates the cache
    queryClient.invalidateQueries({ queryKey: queryKeyDevelopment(platformId) })
    queryClient.invalidateQueries({
      queryKey: queryKeyDevelopmentDomainsForCommands(platformId),
    })

    // redirects
    return redirectToDeveloperHomeOrAggregate(req, params)
  }
