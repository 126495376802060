import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn'
import UndoIcon from '@mui/icons-material/Undo'
import { Alert, Button, MenuItem, Stack, TextField } from '@mui/material'
import {
  Form,
  Link,
  useActionData,
  useNavigation,
  useRouteLoaderData,
} from 'react-router-dom'
import { Row } from '~/components/Row'

export function CodeGenerationStrategiesAdd() {
  const data = useRouteLoaderData('code-generation-strategies') as {
    organisationId: GUID
  }
  const errors = useActionData() as { error: string; key: string }[]
  const navigation = useNavigation()

  return (
    <>
      <Form className="flex-column" method="POST">
        <Stack direction="column" gap={1}>
          <Row className="gap-4">
            <TextField
              label="Strategy Name"
              placeholder="Strategy Name"
              name="name"
              variant="outlined"
              size="small"
              fullWidth
              required
            />
            <TextField
              label="Strategy type"
              name="type"
              required
              select
              size="small"
              defaultValue=""
              fullWidth
            >
              {/* <MenuItem
                className="bg-slate-50 text-primary opacity-100"
                value=""
                disabled
              >
                Select type
              </MenuItem> */}
              <MenuItem
                className="bg-slate-50 text-primary opacity-100"
                value="Private"
              >
                Private
              </MenuItem>
              <MenuItem
                className="bg-slate-50 text-primary opacity-100"
                value="Public"
              >
                Public
              </MenuItem>
            </TextField>
          </Row>
          <Row>
            <TextField
              label="Description"
              placeholder="Strategy description"
              name="description"
              variant="outlined"
              size="small"
              fullWidth
            />
          </Row>
          <Stack direction={'row'} sx={{ justifyContent: 'flex-end' }} gap={2}>
            <Button
              type="submit"
              variant="contained"
              size="small"
              disabled={navigation.state === 'submitting'}
              startIcon={<KeyboardReturnIcon />}
            >
              Submit
            </Button>
            <Button
              variant="outlined"
              size="small"
              component={Link}
              to="../"
              startIcon={<UndoIcon />}
            >
              Cancel
            </Button>
          </Stack>
        </Stack>
      </Form>
      {errors && errors.length && errors[0] ? (
        <div className="mt-4">
          <Alert severity="error">{errors[0].error}</Alert>
        </div>
      ) : null}
    </>
  )
}
