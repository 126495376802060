import { useReactFlow, type Node } from '@xyflow/react'
import { useEffect, useState, type DragEvent } from 'react'
import { FEATURE_TOGGLE } from '~/config/featureToggle'
import type { NodeStatement } from '~/services/Process.types'
import { nodeHeight, nodeWidth } from '../utils'
import { calculateNodeAbsolutePosition } from '../utils/calculateNodeAbsolutePosition'

export const NODES_TRANSITION_DURATION_ON_SELECT = 300
const NODES_TRANSITION_PADDING_ON_SELECT = 8
const NODES_TRANSITION_PADDING_ON_SELECT_WITH_EDITING_BOX = 8
const INCREASE_TOP_VALUE_ON_SELECT_WITH_EDITING = 80

type UseStatementsDiagramReactFlowProps = Pick<NodeStatement, 'isExpanded'> & {
  /** The react flow nodes array. */
  nodes?: Node[]
}

/**
 * Hook to support custom features from the `ReactFlow`
 * component implemented in the `StatementsDiagram`.
 */
export const useStatementsDiagramReactFlow = (
  props: UseStatementsDiagramReactFlowProps,
) => {
  const { isExpanded = false, nodes } = props

  // States.
  const [isReactFlowDragging, setIsReactFlowDragging] = useState<boolean>(false)
  const [isReactFlowSpacePressed, setIsReactFlowSpacePressed] =
    useState<boolean>(false)

  // Hooks.
  const { fitBounds, setCenter } = useReactFlow()

  // Lifecycle.
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.code === 'Space') {
        setIsReactFlowSpacePressed(true)
      }
    }

    const handleKeyUp = (event: KeyboardEvent) => {
      if (event.code === 'Space') {
        setIsReactFlowSpacePressed(false)
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    window.addEventListener('keyup', handleKeyUp)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
      window.removeEventListener('keyup', handleKeyUp)
    }
  }, [setIsReactFlowSpacePressed])

  // Methods.
  const handleReactFlowDragStart = (e: DragEvent<HTMLDivElement>) => {
    setIsReactFlowDragging(true)
  }

  const handleReactFlowDragEnd = (e: DragEvent<HTMLDivElement>) => {
    setIsReactFlowDragging(false)
  }

  /**
   * Handles the react flow fit bounds to centralize the provided node.
   * @node The node to be fit to screen.
   * @zoom The zoom level when fitting to screen.
   */
  const handleNodeFit = (targetNode: Node<NodeStatement>, zoom?: number) => {
    const node = nodes?.find((n) => n.id === targetNode.id)

    if (!!node) {
      const { data } = node
      const { isEditingMode, isLocal } = data || {}

      const isEditingBoxAvailable =
        isEditingMode && isLocal && FEATURE_TOGGLE.BUSINESS.QUICK_EDIT

      // Get absolute position due to parent context nodes.
      const absolutePosition = calculateNodeAbsolutePosition(node, nodes!)

      const bounds = {
        x: absolutePosition.x,
        y:
          absolutePosition.y -
          (isEditingBoxAvailable
            ? INCREASE_TOP_VALUE_ON_SELECT_WITH_EDITING
            : 0),
        width: nodeWidth({ isExpanded }),
        height: nodeHeight({ isExpanded }),
      }

      // Fit bounds to ensure the node is in view.
      fitBounds(bounds, {
        duration: NODES_TRANSITION_DURATION_ON_SELECT,
        padding: isEditingBoxAvailable
          ? NODES_TRANSITION_PADDING_ON_SELECT_WITH_EDITING_BOX
          : NODES_TRANSITION_PADDING_ON_SELECT,
      })

      // Only call setCenter if a zoom level is provided
      if (typeof zoom === 'number') {
        setTimeout(() => {
          const centerX = bounds.x + bounds.width / 2
          const centerY = bounds.y + bounds.height / 2

          // Use setCenter to apply the zoom.
          setCenter(centerX, centerY, {
            zoom: zoom,
            duration: NODES_TRANSITION_DURATION_ON_SELECT,
          })
        }, NODES_TRANSITION_DURATION_ON_SELECT)
      }
    }
  }

  return {
    handleNodeFit,
    handleReactFlowDragEnd,
    handleReactFlowDragStart,
    isReactFlowDragging,
    isReactFlowSpacePressed,
  }
}
