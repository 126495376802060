import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Tooltip,
} from '@mui/material'
import { useState, type MouseEvent, type ReactNode } from 'react'
import OutlineTrashIcon from '~/assets/icons/outline-trash.svg?react'
import TrashIcon from '~/assets/icons/trash.svg?react'

type ConfirmationButtonProps = {
  confirmButtonText: string
  /**
   * The icon to rendered in the button.
   * It defaults to `TrashIcon`.
   */
  icon?: JSX.Element
  itemId: string
  modalText: string
  onConfirmDeleteClick: (itemId: string) => void
  titleText: ReactNode
  tooltipText: string
}

export function ConfirmationButton(props: ConfirmationButtonProps) {
  const {
    confirmButtonText,
    onConfirmDeleteClick: handleConfirmDeletion,
    icon = <TrashIcon />,
    itemId,
    modalText,
    titleText,
    tooltipText,
  } = props

  const [open, setOpen] = useState(false)

  const handleButtonClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    e.preventDefault()
    setOpen(true)
  }

  return (
    <>
      <Tooltip title={tooltipText} placement="right" arrow>
        <IconButton
          className="hidden p-1 group-hover:flex"
          onClick={handleButtonClick}
        >
          {icon}
        </IconButton>
      </Tooltip>

      <Dialog
        onClose={() => setOpen(false)}
        open={open}
        PaperProps={{
          className: 'rounded-lg p-3',
        }}
      >
        <DialogTitle>{titleText}</DialogTitle>

        <DialogContent>
          <DialogContentText>{modalText}</DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={(e) => {
              e.stopPropagation()
              setOpen(false)
            }}
            variant="outlined"
          >
            Cancel
          </Button>

          <Button
            className="bg-rose-500 px-3 text-white hover:bg-rose-600"
            onClick={(e) => {
              e.stopPropagation()
              handleConfirmDeletion(itemId)
              setOpen(false)
            }}
            startIcon={<OutlineTrashIcon />}
          >
            {confirmButtonText}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
