import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import type { ConfigPopperBaseProps } from '~/models/types/components/codeGenerationStrategy/ConfigPopperBaseProps'
import {
  Button,
  Tooltip,
  buttonClasses,
  iconClasses,
} from './ConfigPopper.styles'

export type OneNodeButtonsProps = Pick<
  ConfigPopperBaseProps,
  'node' | 'onDeleteClick' | 'selectedTextSections'
> & {
  /** The callback handler for merge with children nodes button click. */
  onMergeWithChildrenClick?: () => void
}

/**
 * Renders the config buttons when one single node
 * is selected.
 */
export const OneNodeButtons = (props: OneNodeButtonsProps) => {
  const {
    node,
    onDeleteClick,
    onMergeWithChildrenClick,
    selectedTextSections,
  } = props

  const { children: childrenNodes } = node || {}

  if (
    !selectedTextSections?.selected?.length ||
    (selectedTextSections?.selected as GUID[]).length !== 1
  )
    return null

  return (
    <>
      {/* To be added with `add node` endpoint. */}
      {/* <Tooltip title="Add node">
        <LoadingButton
          className={twMerge('mr-2', buttonClasses)}
          // loading={isPending}
          // onClick={onAddClick}
          name="Add node"
        >
          <AddBoxIcon className="h-[20px] w-[20px]" />
        </LoadingButton>
      </Tooltip> */}

      {/* {!!childrenNodes?.length && (
        <Tooltip title="Merge with children nodes">
          <Button
            className={twMerge('mr-2', buttonClasses)}
            onClick={onMergeWithChildrenClick}
            name="Merge with children nodes"
          >
            <CompressIcon className={iconClasses} />
          </Button>
        </Tooltip>
      )} */}

      <Tooltip title="Delete node">
        <Button
          className={buttonClasses}
          onClick={() => onDeleteClick?.(selectedTextSections?.selectedNode)}
          name="Delete node"
        >
          <DeleteForeverIcon className={iconClasses} />
        </Button>
      </Tooltip>
    </>
  )
}
