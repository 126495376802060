import type { FolderOrFileStructure } from '~/services/GenerationStrategy.types'

/**
 * Provides the "FilePath" data from the property bindings.
 * @param data The file or folder data.
 */
export const getFilePathPropertyBinding = (
  data: FolderOrFileStructure | null,
) => {
  if (!data) return

  return data.propertyBindings?.find(
    (binding) => binding.propertyName === 'FilePath',
  )
}
