import EditIcon from '@mui/icons-material/Edit'
import { Button, Typography } from '@mui/material'
import { MouseEvent } from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { DeveloperRoutesEnum } from '~/models/enums/routes/DeveloperRoutesEnum'
import { AggregateInfoHeaderButton } from '~/pages/developer/components/styled/AggregateInfoHeaderButton'
import { Aggregate } from '~/services/Development.types'

type AggregateInfoHeaderNewProps = {
  aggregate: Aggregate
}

export function AggregateInfoHeaderNew(props: AggregateInfoHeaderNewProps) {
  const { aggregate } = props

  // React Router Dom.
  const navigate = useNavigate()

  const params = useParams()
  const { boundedContext = '', organisationId = '', platformId = '' } = params

  // Methods.
  function handleEdit(e: React.MouseEvent<HTMLDivElement>) {
    const edit = e.currentTarget.dataset.edit
    navigate(`edit-${edit}`)
  }

  const handleCloseClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()

    navigate(
      `${generatePath(DeveloperRoutesEnum.DEVELOPER_BOUNDED_CONTEXT, {
        boundedContext,
        organisationId,
        platformId,
      })}?aggregate=${aggregate.identity}`,
    )
  }

  return (
    <div className="flex flex-row items-center justify-between gap-8 pb-0">
      <AggregateInfoHeaderButton
        className="-ml-2"
        p={2}
        gap={1}
        onClick={handleEdit}
        data-edit="details"
      >
        <Typography
          sx={{ lineHeight: 1, strong: { fontWeight: 700 } }}
          variant="subhero"
        >
          <strong>{aggregate.name}</strong>
        </Typography>

        <EditIcon />
      </AggregateInfoHeaderButton>

      <Button variant="outlined" onClick={handleCloseClick}>
        Close
      </Button>
    </div>
  )
}
