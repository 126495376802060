import { type Edge, type Node } from '@xyflow/react'
import tailwindConfig from '^/tailwind.config'
import { type AxiosResponse } from 'axios'
import dagre from 'dagre'
import { produce } from 'immer'
import cloneDeep from 'lodash/cloneDeep'
import groupBy from 'lodash/groupBy'
import { CSSProperties } from 'react'
import resolveConfig from 'tailwindcss/resolveConfig'
import type { StatementsDiagramBaseProps } from '~/models/types/components/processInfo/StatementsDiagramBaseProps'
import {
  STATEMENT_PARSING_INFO_TYPE,
  type NodeStatement,
  type ResponseDiscoveryProcess,
  type Statement,
  type StatementParsingInfo,
} from '~/services/Process.types'
import { getNodeBrandColor } from '~/utils/reactflow/getNodeBrandColor'
import { NodeTypesEnum } from '../StatementsDiagram/CustomNodes/types'

export const nodeWidth = ({ isExpanded }: Pick<NodeStatement, 'isExpanded'>) =>
  isExpanded ? 400 : 230

export const nodeHeight = ({
  isExpanded,
}: Pick<NodeStatement, 'isExpanded'>) => (isExpanded ? 320 : 25)

// Resolve the Tailwind config to get access to the theme.
const fullConfig = resolveConfig(tailwindConfig)

type NodesAndEdges = {
  /** Nodes that will compose the reacfflow. */
  nodes: Node[]
  /** Edges that will compose the reacfflow. */
  edges: Edge[]
}

const getNodeContent = (
  statement: Statement,
  selectedStatements: Statement[],
): NodeStatement => {
  const statementProps = cloneDeep(statement.parsingInfo)

  return {
    ...statementProps,
    errorMessage: statement?.parsingInfo?.errorMessage,
    hasError:
      statement?.parsingInfo?.type &&
      statement?.parsingInfo?.type === STATEMENT_PARSING_INFO_TYPE.Error,
    isEditMutationError: statement.isEditMutationError,
    isEmpty: !statement.content,
    isFeature: statement.isFeature,
    isFetching: statement?.isFetching,
    isLocal: !!selectedStatements.find((s) => s?.id === statement?.id)?.isLocal,
    isSelected: selectedStatements.some((s) => s?.id === statement?.id),
    parentStatementId: statement.parentStatementId,
    retryEditMutation: statement.retryEditMutation,
  }
}

export const parseStatementsToNodesAndEdges = ({
  isEditingMode,
  isExpanded,
  isGeneratingProcess,
  onAddLocalChild,
  onAddLocalNextSibling,
  onAddLocalParent,
  onAddLocalPreviousSibling,
  onAddReaction,
  onDropNodeIntoDropAreas,
  selectedStatements = [],
  setIsEditingMode,
  statementInputRef,
  statements = [],
  updateStatement,
}: StatementsDiagramBaseProps): NodesAndEdges => {
  const nodes: Node[] = []
  const edges: Edge[] = []

  let eventRelations: Array<{
    commandStatementId?: string
    reactionStatementId?: string
    eventId?: string
  }> = []
  let eventsAndCommandsIds: {
    [raisedDomainEventId: string]: {
      commandId: string
    }
  } = {}

  statements.forEach((statement) => {
    const hasChildren = !!statements.find(
      (st) => st.parentStatementId === statement.id,
    )

    const isCurrStatementSelected = !!selectedStatements.find(
      (item) => item.id === statement.id,
    )

    // Set node editable: possible to update it from the node.
    const isEditable =
      isCurrStatementSelected &&
      isEditingMode &&
      !statement.isFeature &&
      !statement.isLocal

    // Set expanded for this node in case it is the one in editing mode.
    const isExpandedNode = isExpanded || (isCurrStatementSelected && isEditable)

    nodes.push({
      id: statement.id || '',
      data: {
        ...getNodeContent(statement, selectedStatements),
        hasChildren,
        isEditable,
        isEditingMode,
        isExpanded: isExpandedNode,
        isGeneratingProcess,
        isSwapModeEnabled: selectedStatements.length > 1,
        onAddLocalChild,
        onAddLocalNextSibling,
        onAddLocalParent,
        onAddLocalPreviousSibling,
        onAddReaction,
        onDropNodeIntoDropAreas,
        setIsEditingMode,
        ...(isEditable && {
          statementInputRef,
        }),
        updateStatement,
      },
      position: {
        x: 0,
        y: 0,
      },
      ...((isExpandedNode || isCurrStatementSelected) && {
        style: { zIndex: 9 },
      }),
      type: isExpandedNode
        ? NodeTypesEnum.EXPANDED_STEP
        : NodeTypesEnum.COLLAPSED_STEP,
    })

    if (statement.parsingInfo?.type === STATEMENT_PARSING_INFO_TYPE.Reaction) {
      if (statement.parsingInfo?.reactingToDomainEvent?.id) {
        eventRelations = [
          ...eventRelations,
          {
            commandStatementId:
              eventsAndCommandsIds[
                statement.parsingInfo.reactingToDomainEvent.id
              ]?.commandId,
            reactionStatementId: statement.id,
            eventId: statement.parsingInfo.reactingToDomainEvent.id,
          },
        ]
      }
    }

    if (statement.parentStatementId) {
      edges.push({
        id: `${statement.parentStatementId}/${statement.id}`,
        source: statement.parentStatementId || '',
        style: {
          strokeDasharray: 6,
          strokeWidth: '2px',
          opacity: isEditingMode ? 0.3 : 0.5,
          ...selectedStatements.find(
            (selectedStatement) =>
              selectedStatement.id === statement.parentStatementId ||
              selectedStatement.id === statement.id,
          ),
        },
        target: statement.id || '',
        type: 'customEdge',
      })
    }

    statement.parsingInfo?.raisedDomainEvents?.forEach((x) => {
      eventsAndCommandsIds = {
        ...eventsAndCommandsIds,
        [x?.id || '']: {
          commandId: statement.id as string,
        },
      }
    })
  })

  eventRelations.forEach((relation) => {
    if (relation.commandStatementId && relation.reactionStatementId) {
      edges.push({
        id: `${relation.commandStatementId}/${relation.reactionStatementId}r`,
        source: relation.commandStatementId,
        target: relation.reactionStatementId,
        style: {
          strokeDasharray: 0,
          strokeWidth: '2px',
          stroke: fullConfig.theme.colors.event.DEFAULT,
          opacity: isEditingMode ? 0.3 : 1,
        },
        type: 'default',
      })
    }
  })

  return {
    nodes,
    edges,
  }
}

export const getLayoutedNodesAndEdgesFromStatements = ({
  isEditingMode,
  isExpanded = false,
  isGeneratingProcess,
  onAddLocalChild,
  onAddLocalNextSibling,
  onAddLocalParent,
  onAddLocalPreviousSibling,
  onAddReaction,
  onDropNodeIntoDropAreas,
  selectedStatements,
  setIsEditingMode,
  statementInputRef,
  statements,
  updateStatement,
}: StatementsDiagramBaseProps): NodesAndEdges => {
  const direction = 'LR' // left to right

  const groupNodes: Node[] = []
  const aggregateGroupNodes: Node[] = []

  const { nodes, edges } = parseStatementsToNodesAndEdges({
    isEditingMode,
    isExpanded,
    isGeneratingProcess,
    onAddLocalChild,
    onAddLocalNextSibling,
    onAddLocalParent,
    onAddLocalPreviousSibling,
    onAddReaction,
    onDropNodeIntoDropAreas,
    selectedStatements,
    setIsEditingMode,
    statementInputRef,
    statements,
    updateStatement,
  })

  const dagreGraph = new dagre.graphlib.Graph({ compound: true })
  dagreGraph.setDefaultEdgeLabel(() => ({}))
  dagreGraph.setGraph({ rankdir: direction, ranksep: 200, nodesep: 200 })

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target)
  })

  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, {
      width: nodeWidth({ isExpanded }),
      height: nodeHeight({ isExpanded }),
    })
  })

  const nodesByContext = groupBy(nodes, 'data.boundedContext')

  Object.entries(nodesByContext).forEach(([context, contextNodes], index) => {
    if (context === 'undefined') return

    dagreGraph.setNode(`bc-${context}`, {
      padding: 200,
    })

    if (isExpanded) {
      const nodesByAggregate = groupBy(contextNodes, 'data.aggregate')
      Object.entries(nodesByAggregate).forEach(
        ([aggregate, aggregateNodes], index) => {
          dagreGraph.setNode(`bc-${context}-a-${aggregate}`, {})

          aggregateNodes.forEach((aggregateNode) => {
            dagreGraph.setParent(
              aggregateNode.id,
              `bc-${context}-a-${aggregate}`,
            )
          })

          dagreGraph.setParent(`bc-${context}-a-${aggregate}`, `bc-${context}`)
        },
      )
    } else {
      contextNodes.forEach((node) => {
        dagreGraph.setParent(node.id, `bc-${context}`)
      })
    }
  })

  dagre.layout(dagreGraph)

  Object.entries(nodesByContext).forEach(([context, contextNodes], index) => {
    if (context === 'undefined') return

    const groupColor = getNodeBrandColor(index)

    const groupNodeReference = dagreGraph.node(`bc-${context}`)

    const groupNode: Node = {
      id: `bc-${context}`,
      type: NodeTypesEnum.GROUP,
      data: {
        label: context,
        width: groupNodeReference.width,
        height: groupNodeReference.height,
        contextName: context,
      },
      position: {
        x: groupNodeReference.x - groupNodeReference.width / 2,
        y: groupNodeReference.y - groupNodeReference.height / 2,
      },
      style: {
        width: groupNodeReference.width,
        height: groupNodeReference.height,
        backgroundColor: `${groupColor}0D`, // the hex value for 5% opacity is 0D
        border: `1px solid ${groupColor}`,
        borderRadius: '8px',
        textAlign: 'center',
        fontSize: '4rem', // Adjust font size for the watermark
        color: `${groupColor}33`, // Use a low-opacity version of the group color
        pointerEvents: 'none',
      } as CSSProperties,
    }

    groupNodes.push(groupNode)

    if (isExpanded) {
      const nodesByAggregate = groupBy(contextNodes, 'data.aggregate')
      Object.entries(nodesByAggregate).forEach(
        ([aggregate, aggregateNodes], index) => {
          const aggregateNodeReference = dagreGraph.node(
            `bc-${context}-a-${aggregate}`,
          )
          const padding = 80

          const aggregateNode: Node = {
            id: `bc-${context}-a-${aggregate}`,
            type: NodeTypesEnum.GROUP,
            data: {
              label: aggregate,
              width: aggregateNodeReference.width,
              height: aggregateNodeReference.height,
              contextName: aggregate,
            },
            position: {
              x:
                aggregateNodeReference.x -
                (aggregateNodeReference.width - padding) / 2,
              y:
                aggregateNodeReference.y -
                (aggregateNodeReference.height - padding) / 2,
            },
            style: {
              width: aggregateNodeReference.width - padding,
              height: aggregateNodeReference.height - padding,
              backgroundColor: `${groupColor}0D`, // the hex value for 5% opacity is 0D
              border: `1px solid ${groupColor}`,
              borderRadius: '8px',
              textAlign: 'center',
              fontSize: '4rem', // Adjust font size for the watermark
              color: `${groupColor}33`, // Use a low-opacity version of the group color
              pointerEvents: 'none',
            } as CSSProperties,
          }

          aggregateGroupNodes.push(aggregateNode)
        },
      )
    }
  })

  nodes.forEach((node) => {
    const nodeWithPosition = dagreGraph.node(node.id)
    node.position = {
      x: nodeWithPosition.x - nodeWidth({ isExpanded }) / 2,
      y: nodeWithPosition.y - nodeHeight({ isExpanded }) / 2,
    }
  })

  return { nodes: [...groupNodes, ...aggregateGroupNodes, ...nodes], edges }
}

export const getLastStatement = (statements: Statement[]): Statement | null => {
  if (statements.length === 0) {
    return null
  }

  const firstRootStatement = statements.find(
    (statement) => statement.parentStatementId === null,
  )

  if (!firstRootStatement) {
    return null
  }

  let currentStatement = firstRootStatement

  while (currentStatement) {
    const firstChildStatement = statements.find(
      (statement) => statement.parentStatementId === currentStatement.id,
    )

    if (!firstChildStatement) {
      break
    }

    currentStatement = firstChildStatement
  }

  return currentStatement
}

export const getStatementById = (
  statements: Statement[],
  statementId: string,
): Statement | undefined => {
  const found = statements.find((statement) => statement.id === statementId)

  return found
}

export const formatStatementList = (
  process: ResponseDiscoveryProcess,
  addInitialLocalStatement: () => Partial<Statement> = () => ({}),
): Statement[] => {
  const newList = process.statements.filter((statement) => !statement.isDeleted)

  if (newList.length === 0 && !process.isGeneratingProcess) {
    newList.push(addInitialLocalStatement() as Statement)
  }

  return cloneDeep(newList)
}

export const getFirstChildStatement = (
  statements: Statement[],
  currentStatementId: string,
): Statement | null => {
  const children = statements.filter(
    (statement) => statement.parentStatementId === currentStatementId,
  )

  return children[0] || null
}

export const getNextSibling = (
  statements: Statement[],
  currentStatement: Statement | null,
): Statement | null => {
  const siblingList = statements.filter(
    (statement) =>
      statement.parentStatementId === currentStatement?.parentStatementId,
  )

  const foundCurrentIndex = siblingList.findIndex(
    (sibling) => sibling.id === currentStatement?.id,
  )

  return siblingList[foundCurrentIndex + 1] || null
}

export const getPreviousSibling = (
  statements: Statement[],
  currentStatement: Statement | null,
): Statement | null => {
  const siblingList = statements.filter(
    (statement) =>
      statement.parentStatementId === currentStatement?.parentStatementId,
  )

  const foundCurrentIndex = siblingList.findIndex(
    (sibling) => sibling.id === currentStatement?.id,
  )

  return siblingList[foundCurrentIndex - 1] || null
}

/**
 * Return an updated array of statements.
 * @param statements The original array of statements.
 * @param updatedStatement The statement to be updated in the array.
 */
export const updateStatement = (
  statements: Statement[],
  updatedStatement: Statement,
): Statement[] => {
  const newList = cloneDeep(statements)

  return newList.map((statement) => {
    if (statement.id === updatedStatement.id) return updatedStatement

    return statement
  })
}

/**
 * Update the provided target statement in the
 * statements from the process.
 * @param currentProcess The current process to update the statement.
 * @param updatedStatement The statement to be updated.
 * @param replaceParsingInfoAction Indicates if the parsing info action should be replaced.
 */
export const updateTargetStatement = (
  currentProcess: AxiosResponse<ResponseDiscoveryProcess>,
  updatedStatement: Statement,
  replaceParsingInfoAction?: boolean,
): AxiosResponse<ResponseDiscoveryProcess> =>
  produce(currentProcess, (draft: AxiosResponse<ResponseDiscoveryProcess>) => {
    const statementId = updatedStatement.id

    // Find the index of the statement to update.
    const statementIndex = draft.data.statements.findIndex(
      (statement) => statement.id === statementId,
    )

    if (statementIndex !== -1) {
      // Replace the whole statement to be updated.
      draft.data.statements[statementIndex] = {
        ...updatedStatement,
      }

      // Replace the parsing info action when it does not exist.
      if (
        replaceParsingInfoAction &&
        !(draft.data.statements[statementIndex] as Statement).parsingInfo
      ) {
        ;(draft.data.statements[statementIndex] as Statement).parsingInfo = {
          action: updatedStatement.content || '',
        } as StatementParsingInfo
      }
    }
  })

export const removeStatement = (
  statements: Statement[],
  statementToRemove: Statement | null,
): Statement[] => {
  const newList = cloneDeep(statements)

  return newList
    .map((statement) => {
      if (statement.id === statementToRemove?.id) {
        return
      }

      if (statement.parentStatementId === statementToRemove?.id) {
        return {
          ...statement,
          parentStatementId: statementToRemove?.parentStatementId,
        }
      }

      return statement
    })
    .filter(Boolean) as Statement[]
}

export const insertLocalBetween = (
  statements: Statement[],
  newStatement: Statement,
): Statement[] => {
  const newList = cloneDeep(statements)

  const parentIndex = newList.findIndex(
    (statement) => statement.id === newStatement.parentStatementId,
  )

  if (parentIndex < 0) {
    return newList
  }

  newList.splice(parentIndex, 0, newStatement)

  return newList.map((statement) => {
    if (
      statement.parentStatementId === newStatement.parentStatementId &&
      statement.id !== newStatement.id
    ) {
      return {
        ...statement,
        parentStatementId: newStatement.id,
      }
    }

    return statement
  })
}

export const swapTwoStatements = (
  statements: Statement[],
  receivedFirstStatement?: Statement,
  receivedSecondStatement?: Statement,
): Statement[] => {
  const newList = cloneDeep(statements)
  const firstStatement = cloneDeep(receivedFirstStatement)
  const secondStatement = cloneDeep(receivedSecondStatement)

  if (firstStatement && secondStatement) {
    const firstStatementIndex = newList.findIndex(
      (statement) => statement.id === firstStatement.id,
    )
    const secondStatementIndex = newList.findIndex(
      (statement) => statement.id === secondStatement.id,
    )

    let secondStatementParentId: string | null

    if (firstStatement.id === secondStatement.parentStatementId) {
      secondStatementParentId = firstStatement.parentStatementId as string
      firstStatement.parentStatementId = secondStatement.id
    } else if (secondStatement.id === firstStatement.parentStatementId) {
      secondStatementParentId = firstStatement.id as string
      firstStatement.parentStatementId = secondStatement.parentStatementId
    } else {
      secondStatementParentId = firstStatement.parentStatementId as string
      firstStatement.parentStatementId = secondStatement.parentStatementId
    }

    secondStatement.parentStatementId = secondStatementParentId

    const filteredList = newList.map((statement) => {
      if (statement.parentStatementId === firstStatement.id) {
        return {
          ...statement,
          parentStatementId: secondStatement.id,
        }
      }

      if (statement.parentStatementId === secondStatement.id) {
        return {
          ...statement,
          parentStatementId: firstStatement.id,
        }
      }

      return statement
    })

    filteredList.splice(firstStatementIndex, 1, secondStatement)
    filteredList.splice(secondStatementIndex, 1, firstStatement)

    return filteredList
  }

  return statements
}
