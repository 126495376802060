import { Button, TextField, Typography } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { useEffect, useMemo } from 'react'
import { FormProvider, useFieldArray, useForm } from 'react-hook-form'
import {
  Form,
  Outlet,
  useActionData,
  useLoaderData,
  useNavigation,
  useParams,
} from 'react-router-dom'
import { ButtonAutoGenerate } from '~/components/ButtonAutoGenerate'
import { Column } from '~/components/Column'
import { ReactionIcon } from '~/components/Icons/ReactionIcon'
import { Row } from '~/components/Row'
import { FormActionName } from '~/pages/developer/components/FormActionName'
import { FormAttributes } from '~/pages/developer/components/FormAttributes'
import { FormFooter } from '~/pages/developer/components/FormFooter'
import { RaisedDomainEvent } from '~/pages/developer/components/FormRaisedDomainEvent'
import { DeveloperChildrenParams } from '~/routes/developer/routes.types'
import { queryDevelopment } from '~/services/Development'
import {
  Attribute,
  CommandDomainEvent,
  DomainByPlatformId,
} from '~/services/Development.types'
import { commandsByAggregate } from '~/utils/transform'
import { useAggregateActionForm } from '../hooks/useAggregateActionForm'
import { renamePropertiesToAttributes } from '../utils'

export type AttributesFormValues = {
  events: CommandDomainEvent[] | undefined
  command: {
    name: string | undefined
    actor: string | undefined
    attributes: Attribute[]
  }
}

type DeveloperEditCommandProps = {
  showBoundedContextEdit: boolean
}

export function DeveloperEditCommand(props: DeveloperEditCommandProps) {
  // React Router Dom.
  const dataAutoGeneratedAttributes = useActionData() as {
    formName: string
    attributes: Attribute[]
  }
  const initialData = useLoaderData() as {
    dataFetched: Awaited<AxiosResponse<DomainByPlatformId | null, unknown>>
  }
  const navigation = useNavigation()
  const params = useParams<DeveloperChildrenParams & { commandId: GUID }>()

  // Queries.
  const { data } = useQuery({
    ...queryDevelopment(params.platformId),
    initialData: initialData.dataFetched,
    select: (response) => response.data,
  })

  // Memoized resources.
  const selectedCommand = useMemo(() => {
    const commands = commandsByAggregate(data)

    if (commands) {
      return commands.find((command) => command.identity === params.commandId)
    }

    return
  }, [data, params.commandId])

  const shouldDisableAttributes = useMemo(() => {
    if (navigation.formData) {
      const checkIfButtonWasClicked = Object.fromEntries(
        navigation.formData,
      ).commandAutoGenerateAttributes
      if (checkIfButtonWasClicked) {
        return true
      }
    }

    return false
  }, [navigation.formData])

  // React Hook Form.
  const methods = useForm({
    defaultValues: {
      events: renamePropertiesToAttributes(selectedCommand?.domainEvents) as
        | CommandDomainEvent[]
        | undefined,
      command: {
        name: selectedCommand?.name,
        actor: selectedCommand?.actor,
        typeId: selectedCommand?.typeId,
        attributes: renamePropertiesToAttributes(
          (dataAutoGeneratedAttributes?.formName === 'command.' &&
            dataAutoGeneratedAttributes?.attributes) ||
            selectedCommand?.attributes,
        ) as CommandDomainEvent[] | undefined,
      },
    },
  })

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: 'events',
  })

  // Hooks.
  const { formProps, submitButtonRef } = useAggregateActionForm()

  // Methods.
  function handleRemoveEvent(event: EventFor<'button', 'onClick'>) {
    const index = event.currentTarget.dataset.index
    if (index) {
      remove(Number(index))
    }
  }

  function handleAddRaisedDomainEvent() {
    append({ name: '', attributes: [] } as unknown as CommandDomainEvent)
  }

  // Lifecycle.
  useEffect(() => {
    if (
      dataAutoGeneratedAttributes &&
      dataAutoGeneratedAttributes?.formName === 'command' &&
      dataAutoGeneratedAttributes?.attributes.length > 0
    ) {
      methods.setValue(
        'command.attributes',
        renamePropertiesToAttributes(
          dataAutoGeneratedAttributes?.attributes,
        ) as CommandDomainEvent[],
      )
    }
  }, [dataAutoGeneratedAttributes, methods])

  return (
    <>
      <Column className="gap-4 pb-12">
        <FormProvider {...methods}>
          <Form method="post" id="form-aggregate-edit-command" {...formProps}>
            <input
              name="aggregateId"
              id="aggregateId"
              type="hidden"
              value={selectedCommand?.aggregateId}
            />

            <Column className="gap-4">
              {props.showBoundedContextEdit ? (
                <FormActionName
                  defaultValue={selectedCommand?.boundedContext}
                  inputLabel="Bounded Context"
                  inputName="boundedContext"
                >
                  <TextField
                    label="Aggregate"
                    variant="outlined"
                    defaultValue={selectedCommand?.aggregateName}
                    name="aggregateName"
                    required
                    fullWidth
                  />
                </FormActionName>
              ) : (
                <></>
              )}

              <FormActionName
                defaultValue={selectedCommand?.name}
                inputName="command.name"
              >
                <input
                  type="hidden"
                  value={selectedCommand?.typeId}
                  {...methods.register('command.typeId')}
                />

                <TextField
                  label="Actor"
                  variant="outlined"
                  name="command.actor"
                  defaultValue={selectedCommand?.actor}
                  fullWidth
                  sx={{
                    mb: 2,
                  }}
                />

                <FormAttributes
                  aggregateId={selectedCommand?.aggregateId}
                  autoGenerateComponent={<ButtonAutoGenerate name="command." />}
                  formName="command"
                  isDisabled={shouldDisableAttributes}
                />
              </FormActionName>

              <Row className="gap-1">
                <ReactionIcon />
                <Typography>Raised Domain Events ({fields.length})</Typography>
              </Row>

              {fields.map((field, index) => (
                <RaisedDomainEvent
                  key={field.id}
                  defaultValue={field.name}
                  index={index}
                  handleRemoveEvent={handleRemoveEvent}
                  inputName={`events.${index}.name`}
                >
                  <FormAttributes
                    aggregateId={selectedCommand?.aggregateId}
                    autoGenerateComponent={
                      <ButtonAutoGenerate name={`events.${index}.`} />
                    }
                    formName={`events.${index}`}
                  />
                </RaisedDomainEvent>
              ))}

              <Button variant="outlined" onClick={handleAddRaisedDomainEvent}>
                Add raised domain event
              </Button>
            </Column>

            <FormFooter submitButtonRef={submitButtonRef} />
          </Form>
        </FormProvider>
      </Column>

      <Outlet />
    </>
  )
}
