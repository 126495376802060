import {
  CheckCircle as CheckCircleIcon,
  Error as ErrorIcon,
  FilterList as FilterListIcon,
  Info as InfoIcon,
  Pending as PendingIcon,
  Refresh as RefreshIcon,
  Schedule as ScheduleIcon,
  Search as SearchIcon,
} from '@mui/icons-material'
import {
  Box,
  Chip,
  IconButton,
  InputAdornment,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Paper,
  Popover,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import React, { useMemo, useState } from 'react'

export interface FileStatus {
  id: string
  relativePath: string
  status:
    | 'completed'
    | 'failed'
    | 'processing'
    | 'pending'
    | 'file path processed'
  progress?: number
}

interface FileProcessingStatusProps {
  files: FileStatus[]
  onRefresh?: () => void
}

const getStatusIcon = (status: FileStatus['status']) => {
  switch (status.toLowerCase()) {
    case 'completed':
      return <CheckCircleIcon fontSize="small" sx={{ color: '#4caf50' }} />
    case 'failed':
      return <ErrorIcon fontSize="small" sx={{ color: '#f44336' }} />
    case 'processing':
      return <ScheduleIcon fontSize="small" sx={{ color: '#3f51b5' }} />
    case 'pending':
      return <PendingIcon fontSize="small" sx={{ color: '#ffa000' }} />
    case 'file path processed':
      return <InfoIcon fontSize="small" sx={{ color: '#2196f3' }} />
  }
}

const getStatusColor = (status: FileStatus['status']) => {
  switch (status.toLowerCase()) {
    case 'completed':
      return '#4caf50'
    case 'failed':
      return '#f44336'
    case 'processing':
      return '#3f51b5'
    case 'pending':
      return '#ffa000'
    case 'file path processed':
      return '#2196f3'
  }
}

export const FileProcessingStatus: React.FC<FileProcessingStatusProps> = ({
  files,
  onRefresh,
}) => {
  const [searchTerm, setSearchTerm] = useState('')
  const [statusFilter, setStatusFilter] = useState<
    FileStatus['status'] | 'all'
  >('all')
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleFilterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleFilterClose = () => {
    setAnchorEl(null)
  }

  const handleStatusFilterChange = (status: FileStatus['status'] | 'all') => {
    setStatusFilter(status)
    handleFilterClose()
  }

  const filteredFiles = useMemo(() => {
    return files.filter((file) => {
      const matchesSearch = file.relativePath
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
      const matchesStatus =
        statusFilter === 'all' ||
        file.status.toLowerCase() === statusFilter.toLowerCase()
      return matchesSearch && matchesStatus
    })
  }, [files, searchTerm, statusFilter])

  const formatStatus = (status: string) => {
    return status
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
  }

  return (
    <Paper
      elevation={3}
      sx={{
        width: '100%',
        maxWidth: '600px',
        margin: 'auto',
        overflow: 'hidden',
        borderRadius: 2,
        height: '91.5vh',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box sx={{ bgcolor: '#3f51b5', p: 2, color: 'white' }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">File Processing Status</Typography>
          <Box>
            <IconButton color="inherit" onClick={onRefresh} size="small">
              <RefreshIcon />
            </IconButton>
            <IconButton
              color="inherit"
              onClick={handleFilterClick}
              size="small"
            >
              <FilterListIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>

      <Box sx={{ p: 2 }}>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Search files..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon color="action" />
              </InputAdornment>
            ),
          }}
        />
      </Box>

      <List sx={{ flexGrow: 1, overflowY: 'auto', px: 2 }}>
        {filteredFiles.map((file, index) => (
          <ListItem
            key={file.id}
            sx={{
              py: 1,
              bgcolor: index % 2 === 0 ? 'background.paper' : 'action.hover',
              borderRadius: 1,
              mb: 1,
            }}
          >
            <ListItemText
              primary={
                <Box display="flex" alignItems="center">
                  {getStatusIcon(file.status)}
                  <Typography
                    variant="body1"
                    sx={{ ml: 1, color: '#3f51b5', fontWeight: 'medium' }}
                  >
                    {file.relativePath.split('/').pop() || file.relativePath}
                  </Typography>
                </Box>
              }
              secondary={
                <Box sx={{ mt: 1, display: 'flex', alignItems: 'center' }}>
                  <Tooltip title={`Full path: ${file.relativePath}`} arrow>
                    <Chip
                      label={formatStatus(file.status)}
                      size="small"
                      sx={{
                        bgcolor: getStatusColor(file.status),
                        color: 'white',
                        fontWeight: 'bold',
                      }}
                    />
                  </Tooltip>
                  {file.status.toLowerCase() === 'processing' &&
                    file.progress !== undefined && (
                      <Box sx={{ flexGrow: 1, ml: 2 }}>
                        <LinearProgress
                          variant="determinate"
                          value={file.progress}
                          sx={{
                            height: 6,
                            borderRadius: 3,
                          }}
                        />
                      </Box>
                    )}
                </Box>
              }
            />
          </ListItem>
        ))}
      </List>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleFilterClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={() => handleStatusFilterChange('all')}>All</MenuItem>
        <MenuItem onClick={() => handleStatusFilterChange('completed')}>
          Completed
        </MenuItem>
        <MenuItem onClick={() => handleStatusFilterChange('failed')}>
          Failed
        </MenuItem>
        <MenuItem onClick={() => handleStatusFilterChange('processing')}>
          Processing
        </MenuItem>
        <MenuItem onClick={() => handleStatusFilterChange('pending')}>
          Pending
        </MenuItem>
        <MenuItem
          onClick={() => handleStatusFilterChange('file path processed')}
        >
          File Path Processed
        </MenuItem>
      </Popover>
    </Paper>
  )
}

export default FileProcessingStatus
