import AddCircleIcon from '@mui/icons-material/AddCircle'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { Fade, IconButton, Tooltip } from '@mui/material'
import { MouseEvent } from 'react'
import { useFieldArray } from 'react-hook-form'
import { v4 as uuidv4 } from 'uuid'
import { StatementEditionBoxFormEnum as FormEnum } from '~/models/enums/forms/StatementEditionBoxFormEnum'
import type { StatementsDiagramBaseProps } from '~/models/types/components/processInfo/StatementsDiagramBaseProps'
import type { StatementsRequiredData } from '~/services/Process.types'
import type { UpdateStatementBaseProps } from '../Step/Step.types'
import { ControlledTextField } from './ControlledTextField'

type ContentRequiredDataInfoProps = UpdateStatementBaseProps &
  Pick<StatementsDiagramBaseProps, 'onAddReaction'>

export const ContentRequiredDataInfo = (
  props: ContentRequiredDataInfoProps,
) => {
  const {
    control,
    data: { data },
    onSubmit,
  } = props

  const { isEditable, isFetching, requiredDataInfo } = data || {}

  const {
    fields: requiredDataInfoFields,
    append: requiredDataInfoAppend,
    remove: requiredDataInfoRemove,
  } = useFieldArray({
    control,
    name: FormEnum.REQUIRED_DATA_INFO,
  })

  const handleAddNewRequiredData = () => {
    requiredDataInfoAppend({
      identity: uuidv4(),
      name: '',
    } as StatementsRequiredData)
  }

  const handleRemoveRequiredDataArrayItem = (
    e: MouseEvent<HTMLButtonElement>,
    index: number,
  ) => {
    e.stopPropagation()
    requiredDataInfoRemove(index)
    onSubmit?.()
  }

  return (
    <div className="w-full">
      <div className="mb-2 flex items-center justify-between">
        <span className="text-xs font-semibold uppercase text-[#0a2342]">
          REQUIRED DATA
        </span>
        {isEditable && (
          <Fade in={true}>
            <IconButton
              aria-label="add required data info"
              className="p-0 text-sm transition-all hover:text-[#9b59b6]"
              disabled={isFetching}
              onClick={handleAddNewRequiredData}
            >
              <AddCircleIcon fontSize="small" />
            </IconButton>
          </Fade>
        )}
      </div>

      <div className="space-y-2">
        {isEditable
          ? requiredDataInfoFields?.map((field, index) => (
              <div key={field.id} className="flex items-center">
                <ControlledTextField
                  className="flex-1"
                  control={control}
                  inputProps={{
                    className:
                      'p-2 py-1 text-sm rounded-full bg-primary text-white placeholder-white/60',
                  }}
                  name={`${FormEnum.REQUIRED_DATA_INFO}.${index}.name`}
                  onBlur={onSubmit}
                  placeholder="Enter required data..."
                />
                <IconButton
                  disabled={isFetching}
                  onClick={(e) => handleRemoveRequiredDataArrayItem(e, index)}
                  size="small"
                  className="ml-1"
                >
                  <DeleteForeverIcon fontSize="small" />
                </IconButton>
              </div>
            ))
          : requiredDataInfo?.map(
              ({ identity, name }: StatementsRequiredData) => (
                <Tooltip key={identity} title={name} arrow placement="right">
                  <div
                    key={identity}
                    className="truncate rounded-full bg-primary p-2 text-sm text-white"
                  >
                    {name}
                  </div>
                </Tooltip>
              ),
            )}
      </div>
    </div>
  )
}
