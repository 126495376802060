import AddIcon from '@mui/icons-material/Add'
import { Button, Typography } from '@mui/material'
import { useEffect, type MouseEvent } from 'react'
import { FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { Form, useActionData } from 'react-router-dom'
import { ButtonAutoGenerate } from '~/components/ButtonAutoGenerate'
import { Column } from '~/components/Column'
import { Row } from '~/components/Row'
import { FormActionName } from '~/pages/developer/components/FormActionName/index'
import { FormAttributes } from '~/pages/developer/components/FormAttributes'
import { FormFooter } from '~/pages/developer/components/FormFooter'
import { FormQueries } from '~/pages/developer/components/FormQueries'
import { Attribute } from '~/services/Development.types'
import { useAggregateActionForm } from '../hooks/useAggregateActionForm'
import { renamePropertiesToAttributes } from '../utils'

export function DeveloperAddReadModel() {
  const dataAutoGeneratedAttributes = useActionData() as {
    formName: string
    attributes: Attribute[]
  }

  const methods = useForm({
    defaultValues: {
      action: {
        name: '',
        attributes: [],
      },
      queries: [
        {
          name: '',
          attributes: [],
        },
      ],
    },
  })

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: 'queries',
  })

  const { formProps, submitButtonRef } = useAggregateActionForm()

  const handleAddQuery = () => {
    append({
      name: '',
      attributes: [],
    })
  }

  const handleRemoveQuery = (event: MouseEvent<HTMLButtonElement>) => {
    const index = event.currentTarget.dataset.index
    if (index) {
      remove(Number(index))
    }
  }

  useEffect(() => {
    if (
      dataAutoGeneratedAttributes &&
      dataAutoGeneratedAttributes?.formName === 'readModel' &&
      dataAutoGeneratedAttributes?.attributes.length > 0
    ) {
      methods.setValue(
        'action.attributes',
        renamePropertiesToAttributes(dataAutoGeneratedAttributes?.attributes),
      )
    }
  }, [dataAutoGeneratedAttributes, methods])

  return (
    <>
      <Column className="gap-4 pb-12">
        <FormProvider {...methods}>
          <Form method="post" id="form-aggregate-add-readmodel" {...formProps}>
            <Column className="gap-4">
              <FormActionName
                inputLabel="Response type"
                inputName="readModel.name"
              >
                <FormAttributes
                  autoGenerateComponent={
                    <ButtonAutoGenerate name="readModel." />
                  }
                  formName="action"
                />
              </FormActionName>

              <Row className="gap-2">
                <Typography>Queries ({fields.length})</Typography>

                <Button
                  variant="outlined"
                  onClick={handleAddQuery}
                  startIcon={<AddIcon />}
                  size="small"
                >
                  Add
                </Button>
              </Row>

              {fields.map((field, index) => (
                <FormQueries
                  key={field.id}
                  defaultValue={field.name || ''}
                  handleRemoveQuery={handleRemoveQuery}
                  isRemoveDisabled={fields.length === 1}
                  index={index}
                  inputName={`queries.${index}.name`}
                  handleAddQuery={handleAddQuery}
                  isLastItem={index === fields.length - 1}
                >
                  <FormAttributes
                    autoGenerateComponent={
                      <ButtonAutoGenerate name={`queries.${index}.`} />
                    }
                    formLabel="Parameters"
                    formName={`queries.${index}`}
                    inputLabel="Parameters"
                  />
                </FormQueries>
              ))}
            </Column>

            <FormFooter submitButtonRef={submitButtonRef} />
          </Form>
        </FormProvider>
      </Column>
    </>
  )
}
