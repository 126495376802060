import AddCircleIcon from '@mui/icons-material/AddCircle'
import AddToPhotosOutlinedIcon from '@mui/icons-material/AddToPhotosOutlined'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { Fade, IconButton, Tooltip } from '@mui/material'
import { Handle, Position } from '@xyflow/react'
import type { MouseEvent } from 'react'
import { useFieldArray } from 'react-hook-form'
import { ReactionIcon } from '~/components/Icons/ReactionIcon'
import { StatementEditionBoxFormEnum as FormEnum } from '~/models/enums/forms/StatementEditionBoxFormEnum'
import type { StatementsDiagramBaseProps } from '~/models/types/components/processInfo/StatementsDiagramBaseProps'
import type { RaisedDomainEvent } from '~/services/Process.types'
import { generateUniqueIdNumber } from '~/utils/api/generateUniqueIdNumber'
import type { UpdateStatementBaseProps } from '../Step/Step.types'
import { ControlledTextField } from './ControlledTextField'

const HANDLE_BORDER = 2
const HANDLE_CONNECT_CLASSES = 'absolute transform-none border-0 opacity-0'

type ContentRaisedDomainEventProps = UpdateStatementBaseProps &
  Pick<StatementsDiagramBaseProps, 'onAddReaction'> & {
    /** Callback handler for the `raised domain event` mouse down event. */
    onRaisedDomainEventMouseDown: () => void
  }

type DomainEventType = { id: number; name: string }

export const ContentRaisedDomainEvent = (
  props: ContentRaisedDomainEventProps,
) => {
  const {
    control,
    data: { data, id },
    onAddReaction,
    onRaisedDomainEventMouseDown,
    onSubmit,
  } = props

  const { isEditable, isFetching, raisedDomainEvents } = data || {}

  const {
    fields: raisedDomainEventFields,
    append: raisedDomainEventAppend,
    remove: raisedDomainEventRemove,
  } = useFieldArray({
    control,
    name: FormEnum.RAISED_DOMAIN_EVENTS,
  })

  const handleAddRaisedDomainEvent = () => {
    raisedDomainEventAppend({
      id: generateUniqueIdNumber(),
      name: '',
    } as RaisedDomainEvent)
  }

  const handleRemoveRaisedDomainEventArrayItem = (
    e: MouseEvent<HTMLButtonElement>,
    index: number,
  ) => {
    e.stopPropagation()
    raisedDomainEventRemove(index)
    onSubmit?.()
  }

  const handleAddReactionClick = (
    e: MouseEvent<HTMLButtonElement>,
    name: string,
  ) => {
    e.stopPropagation()
    onAddReaction?.(`When ${name}, `, id)
  }

  return (
    <div className="w-full">
      <div className="mb-2 flex items-center justify-between">
        <span className="text-xs font-semibold uppercase text-[#0a2342]">
          OUTPUT
        </span>
        {isEditable && (
          <Fade in={true}>
            <IconButton
              aria-label="add raised domain event"
              className="p-0 text-sm transition-all hover:text-[#ffc300]"
              disabled={isFetching}
              onClick={handleAddRaisedDomainEvent}
            >
              <AddCircleIcon fontSize="small" />
            </IconButton>
          </Fade>
        )}
      </div>

      <div className="space-y-2">
        {isEditable
          ? raisedDomainEventFields?.map((field, index) => (
              <div key={field.id} className="flex items-center">
                <ControlledTextField
                  className="flex-1"
                  control={control}
                  inputProps={{
                    className:
                      'p-2 py-1 text-sm rounded-full bg-event text-[#FFFFFF] placeholder-[#0a2342]/60',
                  }}
                  name={`${FormEnum.RAISED_DOMAIN_EVENTS}.${index}.name`}
                  onBlur={onSubmit}
                  placeholder="Enter event name..."
                />
                <IconButton
                  disabled={isFetching}
                  onClick={(e) =>
                    handleRemoveRaisedDomainEventArrayItem(e, index)
                  }
                  size="small"
                  className="ml-1"
                >
                  <DeleteForeverIcon fontSize="small" />
                </IconButton>
              </div>
            ))
          : raisedDomainEvents?.map(
              ({ id: identity, name }: DomainEventType) => (
                <Tooltip title={name} arrow placement="bottom">
                  <div
                    key={identity}
                    className="group relative max-w-full rounded-full bg-event p-2 text-sm text-[#FFFFFF]"
                    onMouseDown={(e) => {
                      e.stopPropagation()
                      onRaisedDomainEventMouseDown()
                    }}
                  >
                    <div className="truncate">{name}</div>
                    <Handle
                      className={`${HANDLE_CONNECT_CLASSES} left-0 top-0 h-[100%] w-[100%]`}
                      id={String(identity)}
                      position={Position.Right}
                      type="source"
                    />
                    <Handle
                      className="absolute right-0 top-1/2 flex h-6 w-6 -translate-y-1/2 items-center justify-center border-solid border-[#ff8c42] bg-white"
                      id={String(identity)}
                      isConnectable={false}
                      isConnectableStart={false}
                      position={Position.Right}
                      style={{ borderWidth: `${HANDLE_BORDER}px` }}
                      type="source"
                    >
                      <ReactionIcon className="scale-75" viewBox="0 0 22 22" />
                      <Handle
                        className={HANDLE_CONNECT_CLASSES}
                        id={String(identity)}
                        position={Position.Right}
                        style={{
                          top: `-${HANDLE_BORDER}px`,
                          right: `-${HANDLE_BORDER}px`,
                          bottom: `-${HANDLE_BORDER}px`,
                          left: `-${HANDLE_BORDER}px`,
                          width: `calc(100% + ${2 * HANDLE_BORDER}px)`,
                          height: `calc(100% + ${2 * HANDLE_BORDER}px)`,
                        }}
                        type="source"
                      />
                    </Handle>
                    <Tooltip arrow placement="right" title={`When ${name}, `}>
                      <IconButton
                        aria-label="Add Reaction"
                        onClick={(e) => handleAddReactionClick(e, name)}
                        className="absolute right-0 top-1/2 -translate-y-1/2 translate-x-full opacity-0 group-hover:opacity-100"
                      >
                        <AddToPhotosOutlinedIcon
                          className="text-secondary"
                          fontSize="small"
                        />
                      </IconButton>
                    </Tooltip>
                  </div>
                </Tooltip>
              ),
            )}
      </div>
    </div>
  )
}
