import { LoadingButton } from '@mui/lab'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import { MouseEvent, forwardRef, useImperativeHandle, useState } from 'react'
import OutlineTrashIcon from '~/assets/icons/outline-trash.svg?react'

type DialogConfirmDeleteProps = {
  /** The confirm delete button text. */
  confirmBtnText?: string
  /** The dialog content text. */
  content?: string
  /**
   * Indicates if there is a loading in progress:
   * This will disabled the buttons.
   */
  isLoading?: boolean
  /** The callback handler for the confirm delete button click. */
  onConfirmClick?: () => void
  /** The dialog title text. */
  title?: string
}

export type DialogConfirmDeleteHandle = {
  /** Open the dialog. */
  handleOpenDialog: () => void
}

/**
 * Dialog (modal) to confirm the delete of something.
 */
const DialogConfirmDelete = forwardRef<
  DialogConfirmDeleteHandle,
  DialogConfirmDeleteProps
>((props: DialogConfirmDeleteProps, ref) => {
  const {
    confirmBtnText = 'Delete',
    content = 'Once confirmed, this action cannot be undone.',
    isLoading,
    onConfirmClick,
    title = 'Are you sure?',
  } = props

  const [open, setOpen] = useState<boolean>(false)

  const handleOpenDialog = () => {
    setOpen(true)
  }

  // Use imperative to have access to it from the parent component.
  useImperativeHandle(ref, () => ({
    handleOpenDialog,
  }))

  const handleCancelClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    setOpen(false)
  }

  const handleConfirmClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    onConfirmClick?.()
    setOpen(false)
  }

  return (
    <Dialog
      onClose={() => setOpen(false)}
      open={open}
      PaperProps={{
        className: 'rounded-lg p-1',
      }}
    >
      <DialogTitle>{title}</DialogTitle>

      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button
          disabled={isLoading}
          onClick={handleCancelClick}
          variant="outlined"
        >
          Cancel
        </Button>

        <LoadingButton
          className="bg-rose-500 px-3 text-white hover:bg-rose-600"
          loading={isLoading}
          onClick={handleConfirmClick}
          startIcon={<OutlineTrashIcon />}
        >
          {confirmBtnText}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
})

export default DialogConfirmDelete
