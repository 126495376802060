import { produce } from 'immer'
import type { FolderOrFileStructure } from '~/services/GenerationStrategy.types'

/**
 * Filter property buildings from a file data,
 * by removing the "FilePath" and "AllowRewrite" properties.
 * @param data The file or folder data.
 */
export const filterOutFilePathAndAllowRewrite = (
  data: FolderOrFileStructure | null,
) => {
  if (!data) return

  return produce(data, (draft) => {
    draft.propertyBindings = draft.propertyBindings?.filter(
      (binding) =>
        binding.propertyName !== 'FilePath' &&
        binding.propertyName !== 'AllowRewrite',
    )
  })
}
