import type { Attribute } from '~/services/Development.types'
import { TypeData } from '../types/TypeData'

/**
 * Creates a reactflow node object for a given service type.
 * @param typeId The unique identifier for the node.
 * @param typeData An array of attributes associated with the type.
 * @param urlType The type from the url query param.
 */
export const createTypeNode = (
  typeId: string,
  typeData: TypeData,
  urlType?: string | null,
) => ({
  id: typeId,
  data: {
    actions: typeData.actions,
    aggregateId: typeData.aggregateId,
    context: typeData.name,
    content: (
      <div className="flex flex-1 flex-col justify-center px-3 py-2 text-[#020890]">
        {typeData.attributes?.map((attrType: Attribute) => (
          <p key={attrType.name} className="m-0">
            <b>{attrType.name}:</b> {attrType.type}
          </p>
        ))}
      </div>
    ),
    count: typeData?.attributes?.length || 0,
    identity: typeData.identity,
    isSelected: typeId === urlType,
    isAggregateType: true, // Indicates if it is a aggregate type.
    parentNodeId: `ag-${typeData.aggregateId}`,
  },
  position: { x: 0, y: 0 },
  type: 'typeNode',
})
