import { Box, Grid, IconButton, Tooltip, Typography } from '@mui/material'
import { Handle, Position, type Node, type NodeProps } from '@xyflow/react'
import { twMerge } from 'tailwind-merge'
import EditIcon from '~/assets/icons/edit.svg?react'
import { ServiceAndEventsActionTypeEnum } from '~/models/enums/developer/serviceMap/ServiceAndEventsActionTypeEnum'
import type { ServiceAndEventsAction } from '~/models/types/developer/serviceMap/ServiceAndEventsType'
import type { NodeStatement } from '~/services/Process.types'
import { AddType } from '../AddType/AddType'
import { NodeToolbar } from '../NodeToolbar/NodeToolbar'
import { useNodeToolbar } from '../NodeToolbar/useNodeToolbar'
import { ServiceActionSection } from '../ServiceActionSection/ServiceActionSection'
import { nodeHeight, nodeWidth } from '../config'

export function ServiceNode(
  props: NodeProps<Node<NodeStatement<ServiceAndEventsAction[]>>>,
) {
  const { data, id } = props
  const { isSelected } = data

  // Hooks.
  const { handleEditClick } = useNodeToolbar({ data })

  return (
    <Box
      className={twMerge(
        'relative flex cursor-pointer flex-col rounded-[6px] border-[1px] border-solid border-[#d3d3d3] bg-white',
        isSelected && 'border-primary',
      )}
      minWidth={nodeWidth}
      minHeight={nodeHeight}
      maxWidth={500}
    >
      {isSelected && <NodeToolbar data={data} />}

      {isSelected && <AddType context={data.context} {...props} />}

      <Typography
        borderBottom={'1px solid #d3d3d3'}
        fontWeight={'bold'}
        textAlign={'center'}
        color={'#020890'}
        className="py-2"
      >
        {data.context}
      </Typography>

      <Grid container>
        {/* Left column: Properties */}
        <Grid item xs={6} className="p-3">
          <Typography
            className="mb-2 flex items-center justify-between font-bold text-[#020890]"
            component="div"
            variant="subtitle1"
          >
            Properties
            <div>
              <Tooltip arrow placement="top" title="Edit">
                <IconButton
                  className="h-[25px] w-[25px] p-1"
                  onClick={handleEditClick}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>

              <AddType iconButton {...props} />
            </div>
          </Typography>

          <Box className="rounded bg-background-business p-2">
            {data.content}
          </Box>
        </Grid>

        {/* Right column: Actions and Events */}
        <Grid item xs={6} className="p-3">
          <div className="text-[#020890]">
            <Typography variant="subtitle1" className="mb-2 font-bold">
              Actions
            </Typography>

            <ServiceActionSection
              actions={data.actions}
              actionType={ServiceAndEventsActionTypeEnum.COMMAND}
              aggregateId={data.aggregateId}
              typeId={data.isAggregateType ? data.identity : undefined}
              label="Commands"
              tooltipTitle="Add command"
            />

            <ServiceActionSection
              actions={data.actions}
              actionType={ServiceAndEventsActionTypeEnum.REACTION}
              aggregateId={data.aggregateId}
              typeId={data.isAggregateType ? data.identity : undefined}
              label="Reactions"
              tooltipTitle="Add reaction"
            />

            <ServiceActionSection
              actions={data.actions}
              actionType={ServiceAndEventsActionTypeEnum.QUERY}
              aggregateId={data.aggregateId}
              typeId={data.isAggregateType ? data.identity : undefined}
              label="Queries"
              tooltipTitle="Add query"
            />
          </div>
        </Grid>
      </Grid>

      <Handle
        id={`${id}-target-handle`}
        type="target"
        position={Position.Left}
        style={{
          left: '2px',
          visibility: 'hidden',
        }}
      />

      <Handle
        id={`${id}-source-handle`}
        type="source"
        position={Position.Right}
        style={{
          right: '2px',
          visibility: 'hidden',
        }}
      />
    </Box>
  )
}
