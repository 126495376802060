import { Divider, List, ListItemText, Stack, TextField } from '@mui/material'
import { useCallback, useMemo, useRef } from 'react'
import {
  Control,
  Controller,
  ControllerRenderProps,
  FieldValues,
} from 'react-hook-form'
import { Text } from '~/components/Text'
import { ValueBindingTreeItemFormEnum } from '~/models/enums/forms/ValueBindingTreeItemFormEnum'
import { useMappingConditions } from './useMappingConditionsWithUserInput'

export interface MappingConditionProps {
  expanded?: boolean
  control: Control<FieldValues>
  name?: string
  small?: boolean
}
interface MappingConditionGroup {
  name: string
  items: MappingConditionItem[]
}
export interface MappingConditionItem {
  description: string
  condition: string
}
export const MappingCondition = ({
  control,
  name = ValueBindingTreeItemFormEnum.MAPPING_CONDITION,
  small = false,
}: MappingConditionProps) => {
  const { conditions, allConditions } = useMappingConditions()
  const inputRef = useRef<HTMLTextAreaElement>(null)

  const renderConditionGroup = useCallback(
    (
      mapCondition: MappingConditionGroup,
      groupIndex: number,
      field: ControllerRenderProps,
    ) => (
      <Stack key={`group-${groupIndex}`}>
        <Text className="font-semibold ">{mapCondition.name}</Text>
        <List
          sx={{
            overflowY: 'scroll',
            maxHeight: 250,
            margin: 0,
          }}
        >
          {mapCondition.items.map((item, itemIndex) => (
            <ListItemText
              key={`${groupIndex}-${itemIndex}-${item.condition}`}
              primary={item.condition}
              secondary={item.description}
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()

                const input = inputRef.current
                if (!input) return

                const start = input.selectionStart ?? 0
                const end = input.selectionEnd ?? 0

                const currentValue = String(field.value ?? '')
                const newValue =
                  currentValue.substring(0, start) +
                  item.condition +
                  currentValue.substring(end)

                field.onChange(newValue)

                requestAnimationFrame(() => {
                  if (input) {
                    const newCursorPos = start + item.condition.length
                    input.focus()
                    input.setSelectionRange(newCursorPos, newCursorPos)
                  }
                })
              }}
              sx={{
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
              }}
            />
          ))}
        </List>
        <Divider />
      </Stack>
    ),
    [allConditions],
  )

  return (
    <Stack spacing={1} justifyItems={'stretch'}>
      <Controller
        control={control}
        name={name}
        shouldUnregister
        render={({ field }) => {
          const actualValue = useMemo(
            () => String(field.value ?? ''),
            [field.value],
          )
          const handleChange = useCallback(
            (e: React.ChangeEvent<HTMLTextAreaElement>) => {
              field.onChange(e.target.value)
            },
            [field.onChange],
          )
          return (
            <>
              <TextField
                size={small ? 'small' : 'medium'}
                placeholder="Select or add a mapping condition"
                inputRef={inputRef}
                multiline
                value={actualValue}
                onChange={handleChange}
                rows={3}
                onBlur={field.onBlur}
                name={field.name}
                fullWidth
              />
              {conditions.map((mapCondition, index) =>
                renderConditionGroup(mapCondition, index, {
                  ...field,
                  value: actualValue,
                }),
              )}
            </>
          )
        }}
      />
    </Stack>
  )
}
