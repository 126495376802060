import Tooltip from '@mui/material/Tooltip'
import Zoom from '@mui/material/Zoom'
import { twMerge } from '^/tailwind.config'

const COLORS_FOR_PARAMS = [
  'bg-yellow-100 text-yellow-900',
  'bg-lime-100 text-lime-900',
  'bg-teal-100 text-teal-900',
  'bg-blue-100 text-blue-900',
  'bg-violet-100 text-violet-900',
  'bg-fuchsia-100 text-fuchsia-900',
  'bg-orange-100 text-orange-900',
  'bg-amber-100 text-amber-900',
  'bg-emerald-100 text-emerald-900',
  'bg-cyan-100 text-cyan-900',
  'bg-indigo-100 text-indigo-900',
  'bg-purple-100 text-purple-900',
  'bg-red-100 text-red-900',
  'bg-orange-100 text-orange-900',
]

export type ColoredParamsProps = {
  isGroupNode?: boolean
  params: string[]
  value: string
}

export function ColoredParams(props: ColoredParamsProps) {
  const { isGroupNode, params, value } = props

  const regex = /\{(\d{1,2})(?::([a-zA-Z_]+))?\}/g
  const uniqueId = `UNIQUE_ID_${Math.floor(Math.random() * 1000000)}`
  const matches: string[] = []
  let match: RegExpExecArray | null

  while ((match = regex.exec(value)) !== null) {
    matches.push(match[0])
  }

  if (matches.length === 0) {
    return (
      <span
        className={twMerge(
          (value === '' || value === `\r\n`) && !isGroupNode ? 'px-1' : '',
        )}
      >
        {value}
      </span>
    )
  }

  let index = 0
  const parts = value.split(regex).map((part, partIndex) => {
    if (partIndex % 3 === 0) {
      return (
        <span key={`${uniqueId}-${index++}`} data-value={part}>
          {part}
        </span>
      )
    }

    const matchIndex = (partIndex - 1) / 3
    const toNumber = findNumber(matches[matchIndex])
    const paramKey = params[toNumber]
    const backgroundColor = COLORS_FOR_PARAMS[toNumber]?.split(' ')[0]
    const textColor = COLORS_FOR_PARAMS[toNumber]?.split(' ')[1]

    if (partIndex % 3 === 1) {
      return (
        <span key={`${uniqueId}-${index++}`} data-value={matches[matchIndex]}>
          <Tooltip
            title={paramKey}
            TransitionComponent={Zoom}
            TransitionProps={{ timeout: 100 }}
            arrow
            componentsProps={{
              tooltip: {
                className: backgroundColor + ' ' + textColor,
              },
              arrow: {
                className: textColor,
              },
            }}
          >
            <span
              className={twMerge(
                'relative mx-[1px] inline-block font-mono text-black',
                backgroundColor ?? COLORS_FOR_PARAMS[0]!.split(' ')[0],
                'rounded px-1 py-0.5',
              )}
            >
              {matches[matchIndex]}
              <span
                className={twMerge(
                  'absolute -top-5 left-1 font-sans text-xs',
                  textColor ?? COLORS_FOR_PARAMS[0]!.split(' ')[1],
                  backgroundColor ?? COLORS_FOR_PARAMS[0]!.split(' ')[0],
                  'rounded-t px-1',
                )}
              >
                {paramKey}
              </span>
            </span>
          </Tooltip>
        </span>
      )
    }

    return <span key={`${uniqueId}-${index++}`}>{matches[matchIndex + 1]}</span>
  })

  return <>{parts}</>
}

function findNumber(inputString?: string) {
  if (!inputString) return 0
  const regex = /\d+/g
  const matchedStrings = inputString.match(regex)
  const numbers = matchedStrings ? matchedStrings.map(Number) : null
  return numbers?.[0] ? numbers[0] : 0
}
