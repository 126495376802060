import { type Edge, type Node } from '@xyflow/react'
import { ServiceAndEventsActionTypeEnum } from '~/models/enums/developer/serviceMap/ServiceAndEventsActionTypeEnum'
import type {
  ServiceAndEventsAction,
  ServiceAndEventsServiceData,
} from '~/models/types/developer/serviceMap/ServiceAndEventsType'
import type { AggregateType, Attribute } from '~/services/Development.types'
import { createQueryEdge } from '../createQueryEdge'
import type { NodesRelationshipType } from '../types/NodesRelationshipType'
import type { TypeData } from '../types/TypeData'
import { createReactionEdge } from './createReactionEdge'
import { createTypeEdge } from './createTypeEdge'
import { createTypeNode } from './createTypeNode'
import { getRelationType } from './getRelationType'
import { isDefaultAggregateType } from './isDefaultAggregateType'
import type { ServiceMapThemePalette } from './reactFlowColors'
import { removeEndingBrackets } from './removeEndingBrackets'

/**
 * Processes a single type and its attributes by mapping
 * unique types.
 * @param type The type data.
 * @param aggregateId The ID of the service the type may belong to.
 * @param typeMap The types map that holds a single instance of a type.
 */
const mapUniqueTypes = (
  type: AggregateType,
  aggregateId: string,
  typeMap: Map<string, TypeData>,
): void => {
  if (!!type && !!type?.identity) {
    // Get or create the type entry in the map.
    const existingType = typeMap.get(type.identity) || ({} as Partial<TypeData>)

    const actions = [type].reduce((final, curr) => {
      curr.commands.forEach((cmd) =>
        final.push({
          id: cmd.identity,
          type: ServiceAndEventsActionTypeEnum.COMMAND,
          name: cmd.name,
          typeId: cmd.typeId,
          events: cmd.domainEvents,
          subscription: null,
          resultType: null,
        }),
      )

      curr.reactions.forEach((rct) =>
        final.push({
          id: rct.identity,
          type: ServiceAndEventsActionTypeEnum.REACTION,
          name: rct.actionName,
          typeId: rct.actionTypeId,
          events: rct.domainEvents,
          subscription: rct.subscription,
          resultType: null,
        }),
      )

      curr.queries.forEach((query) =>
        final.push({
          id: query.identity,
          type: ServiceAndEventsActionTypeEnum.QUERY,
          name: query.name,
          typeId: query.typeId,
          events: [],
          subscription: null,
          resultType: query.result,
        }),
      )

      return final
    }, [] as ServiceAndEventsAction[])

    const finalExistingType: TypeData = {
      id: existingType.identity || type.identity || '',
      identity: existingType.identity || type.identity || '',
      name: type.name || '',
      aggregateId: existingType.aggregateId || aggregateId,
      parents: existingType.parents || [],
      attributes: type.attributes || type.attributes || [],
      actions: actions || [],
    }

    typeMap.set(type.identity, finalExistingType)
  }
}

/**
 * Set relationship between the aggregate and its types.
 * @param property The aggregate type data.
 * @param typeMap The map with unique types.
 * @param aggregateId The aggregate ID.
 */
const setAggregateTypeRelationship = (
  property: Attribute,
  typeMap: Map<string, TypeData>,
  aggregateId: GUID,
) => {
  if (typeMap.size > 0) {
    // Set relationship with the service node.
    typeMap.forEach((uniqueType, typeId) => {
      if (
        aggregateId === uniqueType.aggregateId &&
        removeEndingBrackets(property.type) === uniqueType.name
      ) {
        const updatedType = {
          ...uniqueType,
          parents: [
            ...uniqueType.parents,
            {
              id: uniqueType.aggregateId,
              relation: getRelationType(property.type),
            },
          ],
        }

        typeMap.set(typeId, updatedType as TypeData)
      }
    })
  }
}

/**
 * Set relationship between the type and its attributes.
 * @param type The current type data to loop the attributes.
 * @param typeMap The types map that holds a single instance of a type.
 * @param aggregateId The aggregate ID.
 */
const setTypeAttributesRelationship = (
  type: AggregateType,
  typeMap: Map<string, TypeData>,
  aggregateId: GUID,
): void => {
  if (!!type && !!type.attributes?.length) {
    // Process attributes of the current type.
    type.attributes?.forEach((attr: Attribute) => {
      // Only check the mapped types if the current attr type is not a default one.
      if (!isDefaultAggregateType(attr.type)) {
        // Loop the types map the set relationship between attr and type.
        typeMap.forEach((uniqueType, typeId) => {
          if (
            aggregateId === uniqueType.aggregateId &&
            removeEndingBrackets(attr.type) === uniqueType.name
          ) {
            const updatedType = {
              ...uniqueType,
              parents: [
                ...uniqueType.parents,
                {
                  id: type.identity,
                  relation: getRelationType(attr.type),
                },
              ],
            }

            typeMap.set(typeId, updatedType as TypeData)
          }
        })
      }
    })
  }
}

/**
 * Callback methods for aggregate type attributes `forEach` loop,
 * to generate the reactflow nodes and edges related to this data type.
 * @param boundedContext The bounded context name.
 * @param service The service data.
 * @param nodes The array of nodes.
 * @param edges The array of edges.
 * @param contextMap The context map.
 * @param colors The colors object.
 * @param urlType The type from the url query param.
 */
export const generateTypeNodesAndEdges = (
  boundedContext: string,
  service: ServiceAndEventsServiceData,
  nodes: Node[],
  edges: Edge[],
  contextMap: Map<string, string>,
  colors: ServiceMapThemePalette,
  urlType?: string | null,
) => {
  if (!service?.types?.length) return

  const { id: aggregateId, properties, types } = service

  // Create a map to store unique types.
  const typeMap = new Map()

  // Process all types and their attributes.
  types.forEach((type) => mapUniqueTypes(type, aggregateId, typeMap))

  // Create relationship between the aggregate and its types.
  properties?.forEach((property) =>
    setAggregateTypeRelationship(property, typeMap, aggregateId),
  )

  // Create relationship between types and its attribute types.
  types.forEach((type) =>
    setTypeAttributesRelationship(type, typeMap, aggregateId),
  )

  // Generate service types nodes and edges.
  if (typeMap.size > 0) {
    typeMap.forEach((type: TypeData, key) => {
      nodes.push(createTypeNode(key, type, urlType))

      type.actions?.forEach((action: ServiceAndEventsAction) => {
        if (action.type == ServiceAndEventsActionTypeEnum.REACTION) {
          const newEdge = createReactionEdge(
            action,
            type.id,
            contextMap,
            colors,
          )

          if (newEdge == null) {
            return
          }

          edges.push(newEdge)
        }

        if (action.type === ServiceAndEventsActionTypeEnum.QUERY) {
          const newEdge = createQueryEdge(
            `${boundedContext}.${service.name}`,
            action,
            type.id,
            contextMap,
            colors,
          )

          if (newEdge == null) {
            return
          }

          edges.push(newEdge)
        }
      })

      // Create edges connecting the current node to its parents.
      type.parents.forEach((parent: NodesRelationshipType) => {
        edges.push(createTypeEdge(parent, key, colors))
      })
    })
  }
}
