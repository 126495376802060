import { useRef } from 'react'
import type { RenderTextSectionNodeProps } from '~/models/types/components/codeGenerationStrategy/RenderTextSectionNodeProps'
import type { TextSection } from '~/services/GenerationStrategy.types'
import ValueBindingTreeItem from '../ValueBindingTreeItem/ValueBindingTreeItem'
import { DelimiterSeparator } from './DelimiterSeparator'
import { replaceValueTabs } from './RenderTextSections.utils'
import { useManageNodeSelection } from './useManageNodeSelection'
import { useSelectedNodesPopper } from './useSelectedNodesPopper'

/**
 * Render contents of a single `TextSection` node.
 */
export const RenderTextSectionNode = (props: RenderTextSectionNodeProps) => {
  const {
    dataDomainDictionary,
    fileId,
    isParentSelectable,
    isParentSelected,
    node,
    onDeletePropertyBindingClick,
    parent,
    parentSectionType,
    siblings,
    shouldRenderSeparator,
    ...rest
  } = props

  // Ref.
  const preRef = useRef<HTMLPreElement>(null)

  // Selected nodes popper.
  useSelectedNodesPopper({
    fileId,
    node,
    onDeletePropertyBindingClick,
    anchorRef: preRef,
  })

  // Node selection manager hook.
  const { isSelectable, isSelected } = useManageNodeSelection({
    node: node as TextSection,
    parent,
    siblings,
  })

  // Render null.
  if (!node) return null

  // Constants.
  const { separator } = parentSectionType || {}

  const { children, delimiter, id, sectionType, value } = node
  const { _t: _tSectionType, hasDelimiter } = sectionType || {}

  const isParentNodeSelectable = isParentSelectable || isSelectable
  const isParentNodeSelected = isParentSelected || isSelected

  // Delimiters.
  const startDelimiter = delimiter?.start
  const endDelimiter = delimiter?.end

  // Methods.
  const computeShouldRenderSeparator = (index: number): boolean => {
    if (!children?.length) return false

    // Case for list without delimiter.
    if (_tSectionType === 'List' && !hasDelimiter)
      return index < children.length - 1

    // Default case.
    return index !== children.length - 1 && index !== children.length - 2
  }

  return (
    <pre
      className="inline align-middle leading-10"
      data-value={JSON.stringify(value)}
      ref={preRef}
    >
      <ValueBindingTreeItem
        {...rest}
        dataDomainDictionary={dataDomainDictionary}
        fileId={fileId}
        initialValue={value}
        isContent
        isParentSelectable={isParentNodeSelectable}
        isParentSelected={isParentNodeSelected}
        node={node}
        parent={parent}
        siblings={siblings}
      />

      <DelimiterSeparator>{startDelimiter}</DelimiterSeparator>

      {children?.map((child, index) => (
        <RenderTextSectionNode
          {...rest}
          isParentSelectable={isParentNodeSelectable}
          isParentSelected={isParentNodeSelected}
          key={child.id}
          dataDomainDictionary={dataDomainDictionary}
          fileId={fileId}
          node={replaceValueTabs(child)}
          onDeletePropertyBindingClick={onDeletePropertyBindingClick}
          parent={id}
          parentSectionType={sectionType}
          siblings={children.map(({ id }: TextSection) => id)}
          shouldRenderSeparator={computeShouldRenderSeparator(index)}
        />
      ))}

      {shouldRenderSeparator && (
        <DelimiterSeparator>{separator}</DelimiterSeparator>
      )}

      <DelimiterSeparator>{endDelimiter}</DelimiterSeparator>
    </pre>
  )
}
